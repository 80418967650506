import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import './Settings.css';

const Settings = () => {
  const [locations, setLocations] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState(null);
    const [roles, setRoles] = useState([]);
    const rolesRef = useRef([]);
  const [gridData, setGridData] = useState({});
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchLocations(); // You can also await this if you need it to complete first
        await fetchRoles();     // Wait for fetchRoles to complete
        await fetchWorkingLimits(); // Then fetchWorkingLimits executes
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [selectedLocationId]);
  

  const fetchLocations = async () => {
    try {
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/locations');
      setLocations(response.data);
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_roles');
      setRoles(response.data);
      rolesRef.current=response.data;
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };


  const handleValidateLocation = async () => {
    let validatedStatus=validated;
      if (validatedStatus) {
          validatedStatus=false;
     }
     else{
       validatedStatus=true;
     }
      try {
        const payload = {
          latitude: latitude,   // Send latitude
          longitude: longitude, // Send longitude
          validated: validatedStatus  // Send validated status (true/false)
        };
  
        try {
          const response = await axios.post('https://heuristic-cray.194-76-27-167.plesk.page/api/location_update', payload);
          console.log('Location updated successfully:', response.data);
          setValidated(validatedStatus);  // Update local state
        } catch (error) {
          console.error('Error updating location:', error);
        }
  
      } catch (error) {
        console.error('Error in handleValidateLocation:', error);
      }
    };
     
    const fetchWorkingLimits = async () => {
      if (selectedLocationId) {
        try {
          // Define parameters for the API request
          const params = { locationId: selectedLocationId };
          const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/workinglimits', { params });
          const workingLimitData = response.data; 
          if (workingLimitData && workingLimitData.length > 0) {
            // Extract the 'data' array from the first element of the response
            const extractedData = workingLimitData[0].data;
        
            // Convert the extracted 'data' array into a key-pair array
            const newGridData = {};
            extractedData.forEach(item => {
                const key = `${item.role}-${item.day}`; // Create a key based on role and day
                newGridData[key] = item.value; // Assign the value to the key, ignoring _id
            });
        
            // Set the gridData with the newly created key-pair array
            setGridData(newGridData);
          } else {
            // If no data found, create a blank gridData object
            const newGridData = {};
            rolesRef.current.forEach(role => {
              ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].forEach(day => {
                newGridData[`${role.role}-${day}`] = 0; // Initialize all entries to 0
              });
            });
    
            // Prepare the data structure for the insert
            const workingLimitsToInsert = Object.keys(newGridData).map(key => {
              const [role, day] = key.split('-');
              return {
                role, // Include role
                day ,   //, // Include day
                value: newGridData[key] // Use value
              };
            });
    
            // Insert the blank array into the database, passing locationId and data as parameters
            await axios.post('https://heuristic-cray.194-76-27-167.plesk.page/api/workinglimit_insert', {
              locationId: selectedLocationId, // Pass locationId in the request body
              data: workingLimitsToInsert // Pass the working limits data array
            });
    
            console.log('Blank working limits inserted successfully:', workingLimitsToInsert);
            // Optionally set gridData to the new blank data
    
            const keypairDataGrid = {};

            workingLimitsToInsert.forEach(item => {
              const key = Object.keys(item)
                .filter(k => k !== 'value')  // Exclude the 'value' field from the key
                .map(k => item[k])           // Get the values of each field
                .join('-');                  // Concatenate them with hyphen
            
              keypairDataGrid[key] = item.value; // Assign the value to the generated key
            });
            setGridData(keypairDataGrid);
            
          }
        } catch (error) {
          console.error('Error fetching working limits:', error);
        }
      }
    };
    
         
  const handleLocationClick = async (id) => {
    setSelectedLocationId(id);
    try {
      const location = locations.find((loc) => loc._id === id);
      if (location) {
        setLatitude(location.latitude);
        setLongitude(location.longitude);
      }

     
    } catch (error) {
      console.error('Error fetching location details:', error);
    }
  };

  const handleSaveChanges = async () => {
    // Convert gridData from a key-value pair object to a JSON-style array
    const jsonDataGrid = Object.keys(gridData).map(key => {
        const [role, day] = key.split('-'); // Split the key into role and day
        return { role, day, value: gridData[key] }; // Return the new structure
    });

    // Convert gridData from an object to an array of values
    let dd = Object.values(jsonDataGrid);
    const payload = {
        locationId: selectedLocationId, // Pass selectedLocationId as locationId
        data: jsonDataGrid // Use the newly created jsonDataGrid
    };

    try {
        await axios.post('https://heuristic-cray.194-76-27-167.plesk.page/api/workinglimits_update', payload);
        console.log('Changes saved successfully:', payload);
    } catch (error) {
        console.error('Error saving changes:', error);
    }
};

  

  const handleInputChange = (role, day, value) => {
    const key = `${role}-${day}`;
    const numericValue = Number(value); // Convert to number
  
    setGridData(prev => ({
      ...prev,
      [key]: isNaN(numericValue) ? 0 : numericValue // Set to 0 if conversion fails
    }));
  };
  

  const validateInput = (event) => {
    const value = event.target.value;
    if (!/^\d*$/.test(value)) {
      event.target.value = '';
    }
  };

  return (
    <div className="settings-container" style={{ display: 'flex', width: '100%', gap: '20px' }}>
      <div className="left-panel" style={{ display: 'block', width: '10%', height: 'auto' }}>
        <h2>Locations</h2>
        <div className="locations-grid">
          {locations.map((location) => (
            <div
              key={location._id}
              className={`location-item ${selectedLocationId === location._id ? 'selected' : ''}`}
              onClick={() => handleLocationClick(location._id)}
            >
              {location.name}
            </div>
          ))}
        </div>
      </div>

      <div className="middle-panel" style={{ display: 'block', width: '45%', height: 'auto' }}>
        <h2>Working Limits</h2>
        <button onClick={handleSaveChanges}>Save Changes</button>
        <div style={{ marginTop: '20px' }}>
          <table style={{ width: '75%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th>Roles</th>
                <th>Mon</th>
                <th>Tue</th>
                <th>Wed</th>
                <th>Thu</th>
                <th>Fri</th>
                <th>Sat</th>
                <th>Sun</th>
              </tr>
            </thead>
            <tbody>
              {roles.map((role) => (
                <tr key={role._id}>
                  <td>{role.role}</td>
                  {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map(day => (
                    <td key={`${role._id}-${day}`}>
                      <input
                        type="text"
                        style={{ width: '1cm' }} 
                        value={gridData[`${role.role}-${day}`] || 0} // Show 0 if no value
                        onChange={(e) => handleInputChange(role.role, day, e.target.value)} 
                        onBlur={validateInput}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="right-panel" style={{ display: 'block', width: '45%', height: 'auto' }}>
        <h2>Map view</h2>
        <div className="map-panel">
          {latitude && longitude ? (
            <LoadScript googleMapsApiKey="AIzaSyDSOXhWqgQRNvcZaF0FaOSKEGZO48wISSU">
              <GoogleMap
                mapContainerStyle={{ height: '400px', width: '100%' }}
                center={{ lat: latitude, lng: longitude }}
                zoom={15}
              >
                <Marker position={{ lat: latitude, lng: longitude }} />
              </GoogleMap>
            </LoadScript>
          ) : (
            <p>Select a location to view its map.</p>
          )}
        </div>

        <div style={{ marginTop: '10px' }}>
          <label>
            <input
              type="checkbox"
              checked={validated}
              onChange={() => { }} 
            />
            Validated
          </label>
          <button onClick={handleValidateLocation} style={{ marginLeft: '10px' }}>
            Validate/Invalidate
          </button>
        </div>
      </div>
    </div>
  );
};

export default Settings;
