import React, { useState, useEffect } from 'react';
import './holidaydialog.css';
import axios from 'axios';

const toDateInputValue = (dateString) => {
  if (!dateString) return '';

  const dateStr = String(dateString).trim();
  if (!dateStr) return '';

  const date = new Date(dateStr);
  const adjustedDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

  return adjustedDate.toISOString().split('T')[0];
};

const toUTCDateString = (dateString) => {
  if (!dateString) return '';

  const date = new Date(dateString);
  return date.toISOString();  // This already returns the ISO string with 'Z' at the end
};

const HolidayDialog = ({ data, onClose, staffMembers }) => {
  const [entries, setEntries] = useState(data?.holidayEntries || []);
  const [editIndex, setEditIndex] = useState(null);
  const [dialogDate, setDialogDate] = useState(data?.date || '');
  const [staffNameMap, setStaffNameMap] = useState({});

  useEffect(() => {
    if (data?.holidayEntries) {
      setEntries(data.holidayEntries);
    }
    if (data?.date) {
      setDialogDate(data.date);
    }

    const staffMap = {};
    staffMembers.forEach(member => {
      staffMap[member._id] = {
        name: `${member.firstName} ${member.lastName}`,
        role: member.role,
        organisation_id: member.default_location_id
      };
    });
    setStaffNameMap(staffMap);
  }, [data, staffMembers]);

  const handleEditClick = (index) => {
    setEditIndex(index);
  };


  const formatDate = (dateString) => {
    if (!dateString) return '';
  
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB'); // Formats date as dd/mm/yyyy
  };

  
  const handleSaveClick = async (index) => {
    try {
      let holidayData = { ...entries[index] };
  
      // Ensure dates are in UTC format with 'Z' at the end
      if (holidayData.start) {
        holidayData.start = new Date(holidayData.start).toISOString();
      }
      if (holidayData.end) {
        holidayData.end = new Date(holidayData.end).toISOString();
      }
      if (holidayData.from_time) {
        holidayData.from_time = new Date(holidayData.from_time).toISOString();
      }
      if (holidayData.to_time) {
        holidayData.to_time = new Date(holidayData.to_time).toISOString();
      }
  
      const staffDetails = staffNameMap[holidayData.staff_id] || {};
      holidayData.staff_name = staffDetails.name || '';
      holidayData.role = staffDetails.role || '';
     
      const newHoliday = {
        staff_id: holidayData.staff_id,
        start: holidayData.start,
        end: holidayData.end,
        comment: holidayData.comment,
        manager_comment: holidayData.manager_comment,
        from_time: holidayData.from_time,
        to_time: holidayData.to_time,
        type: holidayData.type,
        status: 'Approved',
        role: holidayData.role,
        staff_name: holidayData.staff_name,
        unpaid: holidayData.unpaid,
        notfromallowance: holidayData.notfromallowance,
        organisation_id: staffDetails.organisation_id
      };
  
      await axios.post('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_holiday', newHoliday);
  
      setEditIndex(null);
      console.log('Holiday saved successfully!');
    } catch (error) {
      console.error('Error posting new holiday:', error);
    }
  };
    
 
  const handleSaveEditClick = async (index) => {
    try {
      // Get the holiday data for the specific entry at the index
      let holidayData = { ...entries[index] };
  
      // Ensure the holiday ID (_id) exists before proceeding
      if (!holidayData._id) {
        console.error('Holiday ID is missing, cannot edit.');
        return;
      }
  
      // Convert date fields to UTC format with 'Z' at the end
      if (holidayData.start) {
        holidayData.start = new Date(holidayData.start).toISOString();
      }
      if (holidayData.end) {
        holidayData.end = new Date(holidayData.end).toISOString();
      }
      if (holidayData.from_time) {
        holidayData.from_time = new Date(holidayData.from_time).toISOString();
      }
      if (holidayData.to_time) {
        holidayData.to_time = new Date(holidayData.to_time).toISOString();
      }
  
      // Prepare the payload with the updated holiday data
      const payload = {
        _id: holidayData._id, // Send the ID for updating
        ...holidayData,       // Spread the rest of the holiday data
      };
  
      // Make the API call to update the holiday data
      try {
        const response = await axios.post('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_holiday_update', payload);
  
        // Handle successful response (optional, depends on how you want to use the response)
        console.log('Holiday updated successfully:', response.data);
        setEditIndex(null);
      } catch (error) {
        console.error('Error updating holiday:', error);
      }
  
    } catch (error) {
      console.error('Error in handleSaveEditClick:', error);
    }
  };
  


  const handleAddClick = () => {
    const newEntryIndex = entries.length;
    setEntries([
      ...entries,
      {
        _id: null,
        staff_id: '',
        start: '',
        end: '',
        type: 'Whole day(s)',
        status: 'Approved',
        comment: '',
        manager_comment: '',
        from_time: '',
        to_time: '',
        staff_name: '',
        role: '',
        unpaid: false,
        notfromallowance: false,
        organisation_id: ''
      },
    ]);
    setEditIndex(newEntryIndex);
  };

  const handleInputChange = (index, field, value) => {
    const updatedEntries = [...entries];
    updatedEntries[index][field] = value;

    if (field === 'staff_id') {
      const staffDetails = staffNameMap[value] || {};
      updatedEntries[index].staff_name = staffDetails.name || '';
      updatedEntries[index].role = staffDetails.role || '';
      updatedEntries[index].organisation_id = staffDetails.organisation_id || '';
      updatedEntries[index].status = staffDetails.status || '';
      
      updatedEntries[index].unpaid = staffDetails.unpaid || false;
      updatedEntries[index].notfromallowance = staffDetails.notfromallowance || false;
 
    }

    setEntries(updatedEntries);
  };

  const handleDeleteClick = (index) => {
    if (window.confirm('Are you sure you want to delete this entry?')) {
      const updatedEntries = entries.filter((_, i) => i !== index);
      setEntries(updatedEntries);
      if (editIndex === index) {
        setEditIndex(null);
      }
    }
  };

  if (!data) return null;

  const formattedMainDate = new Date(dialogDate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' });

  return (
    <div className="holiday-dialog">
    <h2 style={{ paddingTop: '20px' }}>Details for {formattedMainDate}</h2>

      <div className="entry-grid">
        {entries && entries.length > 0 ? (
          entries.map((entry, index) => (
            <div key={entry._id || index} className="entry-item">
              {editIndex === index ? (
                <>
                  <div className="field-container-inline">
                    <label>Staff</label>
                    <select
                      value={entry.staff_id || ''}
                      onChange={(e) => handleInputChange(index, 'staff_id', e.target.value)}
                    >
                      <option value="">Select staff member</option>
                      {staffMembers.map((member) => (
                        <option key={member._id} value={member._id}>
                          {`${member.firstName} ${member.lastName}`}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="field-container-inline">
                    <label>Start</label>
                    <input
                      type="date"
                      value={toDateInputValue(entry.start)}
                      onChange={(e) => handleInputChange(index, 'start', e.target.value)}
                    />
                  </div>
                  <div className="field-container-inline">
                    <label>End</label>
                    <input
                      type="date"
                      value={toDateInputValue(entry.end)}
                      onChange={(e) => handleInputChange(index, 'end', e.target.value)}
                    />
                  </div>
                  <div className="field-container">
                    <label>Type</label>
                    <select
                      value={entry.type || ''}
                      onChange={(e) => handleInputChange(index, 'type', e.target.value)}
                    >
                      <option value="Whole day(s)">Whole day(s)</option>
                      <option value="Part day">Part day</option>
                    </select>
                  </div>
                  {entry.type === 'Part day' && (
                    <>
                      <div className="field-container">
                        <label>From</label>
                        <input
                          type="time"
                          value={entry.from_time || ''}
                          onChange={(e) => handleInputChange(index, 'from_time', e.target.value)}
                        />
                      </div>
                      <div className="field-container">
                        <label>To</label>
                        <input
                          type="time"
                          value={entry.to_time || ''}
                          onChange={(e) => handleInputChange(index, 'to_time', e.target.value)}
                        />
                      </div>
                    </>
                  )}
                  <div className="field-container">
                    <label>Comment</label>
                    <textarea
                      style={{ height: '50px' }}
                      value={entry.comment || ''}
                      onChange={(e) => handleInputChange(index, 'comment', e.target.value)}
                    />
                  </div>
                  <div className="field-container">
  <label>Manager comment</label>
  <textarea
    style={{ height: '50px' }}
    value={entry.manager_comment || ''}
    onChange={(e) => handleInputChange(index, 'manager_comment', e.target.value)}
  />
</div>
<div className="field-container">
  <label>Status</label>
  <select
    value={entry.status || ''}
    onChange={(e) => handleInputChange(index, 'status', e.target.value)}
  >
    <option value="">Select a status</option>
    <option value="Requested">Requested</option>
    <option value="Approved">Approved</option>
    <option value="Declined">Declined</option>
  </select>
</div>


<div className="field-container-unpaidallowance">
  <label>Unpaid</label>
  <input
    type="checkbox"
    checked={entry.unpaid || false}
    onChange={(e) => handleInputChange(index, 'unpaid', e.target.checked)}
  />
</div>

<div className="field-container-unpaidallowance">
  <label>Not from Allowance</label>
  <input
    type="checkbox"
    checked={entry.notfromallowance || false}
    onChange={(e) => handleInputChange(index, 'notfromallowance', e.target.checked)}
  />
</div>

                  <div className="button-group">
                    
                    <button className="save-holbutton" onClick={() => entry._id? handleSaveEditClick(index) : handleSaveClick(index)}>
                    Save
                    </button>
             
                    <button className="cancel-button" onClick={() => setEditIndex(null)}>Cancel</button>
                  </div>
                </>
              ) : (
                <>
                  <p><strong>Staff:</strong> {entry.staff_name}</p>
                  <p><strong>Start:</strong> {formatDate(entry.start)}</p>
                  <p><strong>End:</strong> {formatDate(entry.end)}</p>
                  {entry.type === 'Part day' && (
                    <>
                      <p><strong>From:</strong> {entry.from_time}</p>
                      <p><strong>To:</strong> {entry.to_time}</p>
                    </>
                  )}
                  <p><strong>Type:</strong> {entry.type}</p>
                  <p><strong>Comment:</strong> {entry.comment}</p>
                  <p><strong>Role:</strong> {entry.role}</p>
                  <p><strong>Manager Comment:</strong> {entry.manager_comment}</p>
                  <p><strong>Status:</strong> {entry.status}</p>


<div className="unpaidallowancecheckbox-container">
  <label>Unpaid</label>
  <input
    type="checkbox"
    checked={entry.unpaid || false}
  />
</div>

<div className="unpaidallowancecheckbox-container">
  <label>Not from Allowance</label>
  <input
    type="checkbox"
    checked={entry.notfromallowance || false}
   />
</div>

                  <div className="button-group">
                    <button className="edit-button" onClick={() => handleEditClick(index)}>Edit</button>
                    <button className="delete-button" onClick={() => handleDeleteClick(index)}>Delete</button>
                  </div>
                </>
              )}
            </div>
          ))
        ) : (
          <p>No entries available</p>
        )}
      </div>

      <button className="add-button" onClick={handleAddClick}>Add New</button>
      <button className="close-button" onClick={onClose}>Close</button>
    </div>
  );
};

export default HolidayDialog;
