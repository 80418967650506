import React, { useState } from 'react';
import axios from 'axios';
import './reviewLogEvent.css'; // Adjust the path as needed
import MapDialog from './Map'; // Import the new MapDialog

const ReviewLogEvent = ({ isOpen, onClose, logEvent, fetchStaffLogEvents }) => {
  const [isLocationDialogOpen, setLocationDialogOpen] = useState(false);
  const [isLogoutDistanceDialogOpen, setLogoutDistanceDialogOpen] = useState(false);
  const [stdhrsworked, setStdHrsWorked] = useState(logEvent.stdhrsworked);
  const [overtimehrs, setOvertimeHrs] = useState(logEvent.overtimehrs);
  const [managerComment, setManagerComment] = useState(logEvent.manager_comment);
  const [holidayStatus, setHolidayStatus] = useState(logEvent.status);

  if (!isOpen) {
    setLocationDialogOpen(false);
    setLogoutDistanceDialogOpen(false);
    return null;
  }

  const formatDate = (input) => {
    if (!input) return 'Invalid Date';
    const date = (typeof input === 'string') ? new Date(input) : input;
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  const formatDateTime = (input) => {
    if (!input) return 'Invalid Date';
    const date = (typeof input === 'string') ? new Date(input) : input;
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    return date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  };

  const handleSave = async () => {
    const updatedLogEvent = {
      _id: logEvent._id,
      stdhrsworked: stdhrsworked,
      overtimehrs: overtimehrs,
      manager_comment: managerComment,
      status: holidayStatus,

    };

    try {
      await axios.post(`https://heuristic-cray.194-76-27-167.plesk.page/api/log_event_update`, updatedLogEvent);
       
      onClose(); // Close the dialog
    } catch (error) {
      console.error('Error updating log event:', error);
    }
  };

  const renderTopLeftPanel = () => (
    <div className="panel">
      <h3>Background</h3>
      <p><strong>Staff name:</strong> {logEvent.staff_name}</p>
      <p><strong>Date:</strong> {formatDate(logEvent.created)}</p>
      <p><strong>Break length:</strong> {logEvent.breaklength}</p>
      <p><strong>Grace period:</strong> {logEvent.graceperiod}</p>
      <p
  style={{
    backgroundColor: logEvent.valid === 'Variance' || logEvent.valid === 'Extra session' ? 'lightcoral' : 'transparent',
  }}
>
  <strong>Day analysis:</strong> {logEvent.valid}
</p>

    </div>
  );

  const renderUpperMiddlePanel = () => (
    <div className="panel">
      <h3>Log In Details</h3>
      <p>
  <strong>Location:</strong> {logEvent.location} &nbsp; {/* Add a space for separation */}
   <span 
    className={`clickable ${logEvent.login_latitude && logEvent.login_longitude ? 'highlight' : ''}`}
    onClick={() => setLocationDialogOpen(true)}
  >
    {logEvent.locationValidated ? 'Validated' : 'Not Validated'}
  </span>
</p>
      <p><strong>Planned log in:</strong> {logEvent.planned_login}</p>
      <p
  style={{
    backgroundColor: logEvent.login_variance > logEvent.graceperiod ? 'lightcoral' : 'inherit',
  }}
>
  <strong>Actual log in:</strong> {formatDateTime(logEvent.actual_login)}{'   '}(Variance: {logEvent.login_variance})
</p>

<p>
<p><strong>Adjusted log in:</strong> {formatDateTime(logEvent.adjusted_login)}</p>

 </p>
     <p><strong>Message:</strong> {logEvent.login_message}</p>
      <p><strong>Comment:</strong> {logEvent.login_comment}</p>
    </div>
  );

  const renderLowerMiddlePanel = () => (
    <div className="panel">
      <h3>Log Out Details</h3>
      <p><strong>Distance at logout (metres): </strong> 
        <span 
          className={`clickable ${logEvent.logout_latitude && logEvent.logout_longitude ? 'highlight' : ''}`}
          onClick={() => setLogoutDistanceDialogOpen(true)}
        >
          {logEvent.logoutdistance_variance}
        </span>
      </p>
      <p><strong>Planned log out:</strong> {logEvent.planned_logout}</p>
    
<p
  style={{
    backgroundColor: logEvent.logout_variance < 0 ? 'lightcoral' : 'inherit',
  }}
>
  <strong>Actual log out:</strong> {formatDateTime(logEvent.actual_logout)}{'     '}(Variance: {logEvent.logout_variance})
</p>

      <p><strong>Message:</strong> {logEvent.logout_message}</p>
      <p><strong>Comment:</strong> {logEvent.logout_comment}</p>
      {logEvent.overtime_requested && (
  <>
    <p style={{ backgroundColor: 'lightcoral' }}>
      <strong>Overtime requested:</strong> {logEvent.overtime_requested}
    </p>
    <p style={{ backgroundColor: 'lightcoral' }}>
      <strong>Overtime available:</strong> {logEvent.overtime_available}
    </p>
  </>
)}
   </div>
  );

  const renderRightPanel = () => (
    <div className="panel">
      <h3>Summary</h3>
      <p><strong>Planned hours on site:</strong> {logEvent.plannedhoursonsite}</p>
      <p><strong>Actual  hours on site:</strong> {logEvent.hoursonsite}</p>
      <p>
        <strong>Standard hrs worked: </strong>
        <input
          type="text"
          value={stdhrsworked}
          onChange={(e) => setStdHrsWorked(e.target.value)}
        />
      </p>
      <p>
        <strong>Overtime hrs worked: </strong>
        <input
          type="text"
          value={overtimehrs}
          onChange={(e) => setOvertimeHrs(e.target.value)}
        />
      </p>
      <p>
        <strong>Manager comment:</strong>
        <textarea
  value={managerComment}
  onChange={(e) => setManagerComment(e.target.value)}  // Same onChange handler
  rows="4"    // Adjust the number of rows as needed
  cols="50"   // Adjust the width as needed
/>

      </p>
      <p><strong>Status:</strong></p>
<div onChange={(e) => setHolidayStatus(e.target.value)} className="radio-group">
  <label>
    <input 
      type="radio" 
      name="status" 
      value="Approved" 
      checked={holidayStatus === 'Approved'} 
    /> Approved
  </label>
  <label>
    <input 
      type="radio" 
      name="status" 
      value="Under Review" 
      checked={holidayStatus === 'Under Review'} 
    /> Under Review
  </label>
</div>


    </div>
  );

  return (
    <div className="popup-overlay">
      <div className="popup">
        <h4>Review Log Event</h4>
        <div className="popup-content">
          <div className="column">
            {renderTopLeftPanel()}
          </div>
          <div className="column">
            {renderUpperMiddlePanel()}
            {renderLowerMiddlePanel()}
          </div>
          <div className="column">
            {renderRightPanel()}
          </div>
        </div>

        <div className="popup-buttons">
          <button className="confirm-button" onClick={handleSave}>Confirm</button>
          <button className="cancel-button" onClick={() => { 
            onClose(); 
            setLocationDialogOpen(false); 
            setLogoutDistanceDialogOpen(false); 
          }}>Cancel</button>
        </div>
      </div>

      {isLocationDialogOpen && (
        <MapDialog
          isOpen={isLocationDialogOpen}
          onClose={() => setLocationDialogOpen(false)}
          latitude={logEvent.login_latitude}
          longitude={logEvent.login_longitude}
          itemid='1'
        />
      )}

      {isLogoutDistanceDialogOpen && (
        <MapDialog
          isOpen={isLogoutDistanceDialogOpen}
          onClose={() => setLogoutDistanceDialogOpen(false)}
          latitude={logEvent.logout_latitude}
          longitude={logEvent.logout_longitude}
        />
      )}
    </div>
  );
};

export default ReviewLogEvent;
