import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'; 
import holidayAllowanceCalculator from './holidayAllowanceCalculator';
import advancedFormat from 'dayjs/plugin/advancedFormat'; 
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement
} from 'chart.js';
import './Staff.css';

dayjs.extend(duration); 
dayjs.extend(advancedFormat); 

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement
);

dayjs.extend(duration); 
dayjs.extend(advancedFormat); 

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement
);



// Sample staffLogEvents array
const staffLogEvents = [
  { date: '2024-09-15', login_variance: 2 },
  { date: '2024-09-16', login_variance: 5 },
  // ... more log events
];

// Define the grace period
const graceperiod = 3; // Example grace period

// Function to aggregate data by month/year
function aggregateData(logEvents, graceperiod) {
  const result = {};

  logEvents.forEach(event => {
    // Extract year and month from the date
    const date = new Date(event.date);
    const yearMonth = `${date.getFullYear()}-${date.getMonth() + 1}`;

    // Initialize counters if not already present
    if (!result[yearMonth]) {
      result[yearMonth] = {
        withinGracePeriod: 0,
        beyondGracePeriod: 0
      };
    }

    // Count based on login_variance
    if (event.login_variance <= graceperiod) {
      result[yearMonth].withinGracePeriod += 1;
    } else {
      result[yearMonth].beyondGracePeriod += 1;
    }
  });

  return result;
}

// Aggregate data
const aggregatedData = aggregateData(staffLogEvents, graceperiod);


// Function to convert aggregated data to series data for the graph
function prepareGraphData(aggregatedData) {
  const dates = [];
  const withinGracePeriodCounts = [];
  const beyondGracePeriodCounts = [];

  for (const [date, counts] of Object.entries(aggregatedData)) {
    dates.push(date);
    withinGracePeriodCounts.push(counts.withinGracePeriod);
    beyondGracePeriodCounts.push(counts.beyondGracePeriod);
  }

  return {
    dates,
    withinGracePeriod: withinGracePeriodCounts,
    beyondGracePeriod: beyondGracePeriodCounts
  };
}





// Prepare data for the graph
const graphData = prepareGraphData(aggregatedData);



const Staff = () => {
  const [staffMembers, setStaffMembers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [logEvents, setLogEvents] = useState([]);
  const [selectedLogEvent, setSelectedLogEvent] = useState(null);
  const [staffSickness, setStaffSickness] = useState([]);
const [staffHolidays, setStaffHolidays] = useState([]);
const [showSuccessDialog, setShowSuccessDialog] = useState(false);
const [showErrorDialog, setShowErrorDialog] = useState(false);
const [errorMessage, setErrorMessage] = useState('');
const [holidayAllowance, setHolidayAllowance] = useState(null);

  const chartRef = useRef(null);

  

  useEffect(() => {
    const loadData = async () => {
      try {
        // Fetch locations and roles
        const locationResponse = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/locations');
        setLocations(locationResponse.data);
  
        const rolesResponse = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_roles');
        setRoles(rolesResponse.data);
  
        // Fetch staff members based on selected location
        const staffUrl = selectedLocation
          ? `https://heuristic-cray.194-76-27-167.plesk.page/api/staff_filtered?default_location_name=${encodeURIComponent(selectedLocation)}`
          : 'https://heuristic-cray.194-76-27-167.plesk.page/api/staff';
        const staffResponse = await axios.get(staffUrl);
  
        // Filter out entries where firstName="Admin" and lastName="Admin"
        const filteredStaffMembers = staffResponse.data.filter(
          (staff) => !(staff.firstName === "Admin" && staff.lastName === "Admin")
        );
  
        const sortedStaffMembers = filteredStaffMembers.sort((a, b) => {
          if (a.default_location_name < b.default_location_name) return -1;
          if (a.default_location_name > b.default_location_name) return 1;
          if (a.role < b.role) return -1;
          if (a.role > b.role) return 1;
          if (a.lastname < b.lastname) return -1;
          if (a.lastname > b.lastname) return 1;
          return 0;
        });
  
        setStaffMembers(sortedStaffMembers);
  
        // Fetch log events if a staff member is selected
        if (selectedStaff && selectedStaff._id) {
          fetchHolidayAllowance();
          const logEventsUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/staff_log_events?staff_id=${selectedStaff._id}`;
          //console.log('Request URL:', logEventsUrl); // For debugging
          const logEventsResponse = await axios.get(logEventsUrl);
          //console.log('Log events fetched:', logEventsResponse.data); // For debugging
  
          // Sort log events in reverse chronological order based on 'created' field
          const sortedLogEvents = logEventsResponse.data.sort((a, b) => new Date(b.created) - new Date(a.created));
          setLogEvents(sortedLogEvents);
  
          // Fetch sickness records
          const sicknessUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/staff_sickness?staff_id=${selectedStaff._id}`;
          const sicknessResponse = await axios.get(sicknessUrl);
          setStaffSickness(sicknessResponse.data);
  
          // Fetch holiday records
          const holidaysUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/staff_holiday?staff_id=${selectedStaff._id}`;
          const holidaysResponse = await axios.get(holidaysUrl);
          setStaffHolidays(holidaysResponse.data);
        }
      } catch (error) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };
  
     loadData();
  }, [selectedLocation, selectedStaff]); // Depend on both selectedLocation and selectedStaff

  const handleLocationChange = (e) => {
    setSelectedLocation(e.target.value);
  };

  const handleStaffClick = (staff) => {
    if (unsavedChanges) {
      if (!window.confirm('You have unsaved changes to this record. Do you want to save them now?')) {
        setUnsavedChanges(false);
      } else {
        saveStaffData();
      }
    }
    setSelectedStaff({ ...staff });
    setActiveTab(1); // Set default tab or any other logic needed
  };


  const fetchHolidayAllowance = async () => {
    if (selectedStaff) {
      try {
        const response = await holidayAllowanceCalculator(selectedStaff._id); // Assuming _id is used as itemid
        setHolidayAllowance(response);
      } catch (error) {
        console.error("Error fetching holiday allowance:", error);
      }
    }
  };


  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUnsavedChanges(true);
    setSelectedStaff({
      ...selectedStaff,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleLocationSelectionChange = (e, locationName) => {
    const { value } = e.target; // Get the selected value (_id)
    setUnsavedChanges(true); // Mark as unsaved changes

    setSelectedStaff((prevState) => ({
        ...prevState,
        default_location_id: value, // Update default_location_id
        default_location_name: locationName, // Update default_location_name
    }));
};



  const saveStaffData = async () => {
    try {
        await axios.post(`https://heuristic-cray.194-76-27-167.plesk.page/api/staff_update`, selectedStaff);
        setUnsavedChanges(false);

        // Show success dialog for a few seconds
        setShowSuccessDialog(true);
        setTimeout(() => {
            setShowSuccessDialog(false);
        }, 3000); // Hide after 3 seconds

    } catch (error) {
        console.error('Error updating staff:', error);
        setErrorMessage('Error updating staff: ' + error.message);
        setShowErrorDialog(true);
    }
};

 
 
  const calculateHoursWorked = (startTime, endTime, breakLength) => {
    if (!startTime || !endTime || isNaN(breakLength)) {
      return 'Invalid input';
    }

    const format = 'HH:mm';
    const start = dayjs(startTime, format);
    const end = dayjs(endTime, format);

    if (!start.isValid() || !end.isValid()) {
      return 'Invalid time';
    }

    let endTimeAdjusted = end;
    if (end.isBefore(start)) {
      endTimeAdjusted = end.add(1, 'day');
    }

    const totalMinutes = endTimeAdjusted.diff(start, 'minute');
    const breakMinutes = parseFloat(breakLength);

    if (isNaN(breakMinutes) || breakMinutes < 0) {
      return 'Invalid break length';
    }

    const hoursWorked = (totalMinutes - breakMinutes) / 60;
    return hoursWorked.toFixed(2);
  };

  const graphData = {
    labels: logEvents.map(event => event._id),
    datasets: [
      {
        label: 'Login Variance',
        data: logEvents.map(event => ({
          x: event._id,
          y: event.login_variance,
        })),
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderWidth: 2,
        pointBackgroundColor: 'rgba(75, 192, 192, 1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(75, 192, 192, 1)',
        fill: false,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const event = logEvents.find(e => e._id === tooltipItem.raw.x);
            return event ? `Login Variance: ${event.login_variance}` : 'N/A';
          },
        },
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Log Event ID'
        },
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false, // Prevents auto skipping of x-axis labels
          maxRotation: 90, // Rotate labels if they are too long
          minRotation: 45, // Minimum rotation angle
        },
      },
      y: {
        min: -50,
        max: 50,
        ticks: {
          callback: (value) => value.toFixed(2),
        },
        grid: {
          display: true,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
        border: {
          display: true,
        },
      },
    },
  };

  
  
  
  const handlePointClick = (event) => {
    const chart = chartRef.current?.chart;
    if (chart) {
      const elements = chart.getElementsAtEventForMode(event.native, 'nearest', { intersect: true }, false);
      if (elements.length) {
        const element = elements[0];
        const logEvent = logEvents.find(e => e._id === element.raw.x);
        setSelectedLogEvent(logEvent);
      }
    }
  };

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="container">
 {/* Left Section: Staff List */}
<div className="left-section">
  <button 
    style={{ marginBottom: '10px', padding: '8px 16px' }} 
    onClick={saveStaffData}
  >
    Save Data
  </button>
  <h3>Staff List</h3>
  <select className="location-select" value={selectedLocation} onChange={handleLocationChange}>
    <option value=''>All Locations</option>
    {locations.map((location) => (
      <option key={location._id} value={location.name}>
        {location.name}
      </option>
    ))}
  </select>
  <div className="staff-list">
    {staffMembers.map((staff, index) => (
      <div
        key={staff._id}
        className={`staff-item ${index % 2 === 0 ? 'even' : 'odd'}`}
        onClick={() => handleStaffClick(staff)}
      >
        <span>
          {staff.lastName}, {staff.firstName} - {staff.default_location_name}
        </span>
      </div>
    ))}
  </div>
</div>

      {/* Middle Section: Edit Form */}
      <div className="middle-section">
        <h3>Edit Staff Member</h3>
        {selectedStaff ? (
          <div>
            {/* Dynamic Form Generation */}
            {Object.keys(selectedStaff).map((key) => (
              key !== 'created' &&
              key !== 'organisation_id' &&
              key !== 'userGuid' &&
              key !== 'deviceId' &&
              key !== 'organisation_name' &&
              key !== 'default_location_name' &&
              key !== 'dailyEntries' &&
              key !== '_id' &&
              key !== '__v' && (
                <div key={key} className="form-group">
                  {key === 'accessRights' ? (
                    <div className="form-group">
                      <label>Access Rights:</label>
                      <select
                        name="accessRights"
                        value={selectedStaff.accessRights || ''}
                        onChange={handleInputChange}
                        className="form-input"
                      >
                        <option value="">Select Access Rights</option>
                        <option value="User">User</option>
                        <option value="Manager">Manager</option>
                      </select>
                    </div>
                  ) : key === 'hourPattern' ? (
                    <div className="form-group">
                      <label>Hour Pattern:</label>
                      <select
                        name="hourPattern"
                        value={selectedStaff.hourPattern || ''}
                        onChange={handleInputChange}
                        className="form-input"
                      >
                        <option value="">Select Hour Pattern</option>
                        <option value="Regular hours">Regular hours</option>
                        <option value="Variable hours">Variable hours</option>
                        <option value="Salary based">Salary based</option>
                      </select>
                    </div>
                  ) : key === 'role' ? (
                    <div className="form-group">
                      <label>Role:</label>
                      <select
                        name="role"
                        value={selectedStaff.role || ''}
                        onChange={handleInputChange}
                        className="form-input"
                      >
                        <option value="">Select Role</option>
                        {roles.map((role) => (
                          <option key={role._id} value={role.role}>
                            {role.role}
                          </option>
                        ))}
                      </select>
                    </div>
) : key === 'default_location_id' ? (
  <div className="form-group">
    <label>Default location:</label>
    <select
      name="default_location_id"
      value={selectedStaff.default_location_id || ''}
      onChange={(event) => {
        const selectedLocation = locations.find(
          (location) => location._id === event.target.value
        );

        handleLocationSelectionChange(event, selectedLocation ? selectedLocation.name : '');
      }}
      className="form-input"
    >
      <option value="">Select Default location</option>
      {locations.map((location) => (
        <option key={location._id} value={location._id}>
          {location.name}
        </option>
      ))}
    </select>
  </div>
                                      ) : key === 'active' ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <label>Active:</label>
                      <input
                        type="checkbox"
                        name="active"
                        checked={selectedStaff.active || false}
                        onChange={handleInputChange}
                        className="form-checkbox"
                        style={{ marginRight: '10px', marginLeft: '10px' }}
                      />
                      <label style={{ marginRight: '10px' }}>First Name:</label>
                      <input
                        type="text"
                        name="firstName"
                        value={selectedStaff.firstName || ''}
                        onChange={handleInputChange}
                        className="form-input small-input"
                      />
                      <label style={{ marginLeft: '20px', marginRight: '10px' }}>Last Name:</label>
                      <input
                        type="text"
                        name="lastName"
                        value={selectedStaff.lastName || ''}
                        onChange={handleInputChange}
                        className="form-input small-input"
                      />
                    </div>
                  ) : key === 'hourlyPayRate' ? (
                    <div className="form-group">
                      <label>Hourly pay rate:</label>
                      <input
                        type="text"
                        name="hourlyPayRate"
                        value={selectedStaff.hourlyPayRate || ''}
                        onChange={handleInputChange}
                        className="form-input"
                      />
                    </div>
                  ) : key === 'holAllowance' ? (
                    <div className="form-group">
                      <label>Holiday allowance wk/yr:</label>
                      <input
                        type="text"
                        name="holAllowance"
                        value={selectedStaff.holAllowance || ''}
                        onChange={handleInputChange}
                        className="form-input"
                      />
                    </div>
                  ) : key === 'holAdjustment' ? (
                    <div className="form-group">
                      <label>Base holiday adjustment (dys):</label>
                      <input
                        type="text"
                        name="holAdjustment"
                        value={selectedStaff.holAdjustment || ''}
                        onChange={handleInputChange}
                        className="form-input"
                      />
                    </div>
                  ) : (
                    key !== 'firstName' &&
                    key !== 'lastName' && (
                      <div className="form-group">
                        <label>
                          {key.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())}:
                        </label>
                        <input
                          type="text"
                          name={key}
                          value={selectedStaff[key] || ''}
                          onChange={handleInputChange}
                          className="form-input"
                        />
                      </div>
                    )
                  )}
                </div>
              )
            ))}
           {/* Daily Entries Tabs */}
<div>
  <div className="tabs">
    {[1, 2, 3, 4].map((week) => (
      <button
        key={week}
        onClick={() => setActiveTab(week)}
        className={`tab-button ${activeTab === week ? 'active' : ''}`}
      >
        Week {week}
      </button>
    ))}
  </div>
  <div className="tab-content">
    {selectedStaff.dailyEntries
      .filter((entry) => entry.weekNumber === activeTab)  // Show only entries for the active week
      .map((entry, index) => (
        <div key={index} className="daily-entry">
          <label>Day {entry.dayOfWeek}:</label>
          <div className="entry-fields">
            <div className="entry-field">
              <label>Start:</label>
              <input
                type="text"
                value={entry.startTime}
                onChange={(e) => {
                  const updatedEntries = selectedStaff.dailyEntries.map((existingEntry, i) =>
                    i === index && existingEntry.weekNumber === activeTab
                      ? { ...existingEntry, startTime: e.target.value } // Update the start time for the active week and index
                      : existingEntry
                  );
                  setSelectedStaff({ ...selectedStaff, dailyEntries: updatedEntries });
                  setUnsavedChanges(true);
                }}
                className="entry-input"
              />
            </div>
            <div className="entry-field">
              <label>End:</label>
              <input
                type="text"
                value={entry.endTime}
                onChange={(e) => {
                  const updatedEntries = selectedStaff.dailyEntries.map((existingEntry, i) =>
                    i === index && existingEntry.weekNumber === activeTab
                      ? { ...existingEntry, endTime: e.target.value } // Update the end time for the active week and index
                      : existingEntry
                  );
                  setSelectedStaff({ ...selectedStaff, dailyEntries: updatedEntries });
                  setUnsavedChanges(true);
                }}
                className="entry-input"
              />
            </div>
            <div className="entry-field">
              <label>Break:</label>
              <input
                type="text"
                value={entry.breakLength}
                onChange={(e) => {
                  const updatedEntries = selectedStaff.dailyEntries.map((existingEntry, i) =>
                    i === index && existingEntry.weekNumber === activeTab
                      ? { ...existingEntry, breakLength: e.target.value } // Update the break length for the active week and index
                      : existingEntry
                  );
                  setSelectedStaff({ ...selectedStaff, dailyEntries: updatedEntries });
                  setUnsavedChanges(true);
                }}
                className="entry-input"
              />
            </div>
            <div className="entry-field">
              <label>Hrs worked:</label>
              <input
                type="text"
                value={calculateHoursWorked(entry.startTime, entry.endTime, entry.breakLength)}
                disabled
                className="entry-input"
              />
            </div>
          </div>
        </div>
      ))}
  </div>
</div>

                       <button
              onClick={saveStaffData}
              disabled={!unsavedChanges}
              className="save-button"
            >
              Save
            </button>
          </div>
        ) : (
          <p>Select a staff member to edit</p>
        )}
      </div>

{/* Right Section: Report Detail */}
<div className="right-section">
 

  <div className="boxes-container">
    {/* Box 1: Holidays */}
    <div className="box">
      <h4>Holidays</h4>
      <ul>
        {staffHolidays.length > 0 ? (
          staffHolidays.map((holiday, index) => (
            <li key={index}>
              {dayjs(holiday.start).format('DD/MM/YY')} - {dayjs(holiday.end).format('DD/MM/YY')}: {holiday.status}
            </li>
          ))
        ) : (
          <li>No holiday records available.</li>
        )}
      </ul>
    </div>


 {/* New Box: Holiday Allowance */}
<div className="box">
  <h4>Holiday Allowance</h4>
  <ul>
    {holidayAllowance && holidayAllowance.length > 0 ? (
      holidayAllowance.map((entry, index) => (
        <li key={index}>
          {entry.year} - Taken: {entry.hrstaken} - Allowed: {entry.hrsallowed}
        </li>
      ))
    ) : (
      <li>No holiday allowance records available.</li>
    )}
  </ul>
</div>

    {/* Box 2: Sickness Log */}
    <div className="box">
      <h4>Sickness Log</h4>
      <ul>
        {staffSickness.length > 0 ? (
          staffSickness.map((sickness, index) => (
            <li key={index}>
              {dayjs(sickness.created).format('DD/MM/YY')} - {sickness.daysAway === 0 ? '-' : sickness.daysAway} (dys) {sickness.status}
            </li>
          ))
        ) : (
          <li>No sickness records available.</li>
        )}
      </ul>
    </div>
    {/* Box 3: Logevents */}
    <div className="box">
      <h4>Log Events</h4>
      <ul>
        {logEvents.length > 0 ? (
          logEvents.map((logevent, index) => (
            <li key={index}>
           {dayjs(logevent.created).format('DD/MM/YY')} - {logevent.log_status} - 
    {logevent.stdhrsworked}/{logevent.overtimehrs} - 
    <input 
      type="checkbox" 
      checked={logevent.overtimerequested} 
      disabled 
    />     
       </li>
          ))
        ) : (
          <li>No log available.</li>
        )}
      </ul>
    </div>



  </div>
{/* Success Dialog */}
{showSuccessDialog && (
    <div className="dialog success-dialog">
        <p>Staff data updated successfully!</p>
    </div>
)}

{/* Error Dialog */}
{showErrorDialog && (
    <div className="dialog error-dialog">
        <p>{errorMessage}</p>
        <button onClick={() => setShowErrorDialog(false)}>OK</button>
    </div>
)}

     
    </div>
    </div>
    
  );
};

export default Staff;
