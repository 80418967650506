import { Bar } from 'react-chartjs-2'; // Ensure you have react-chartjs-2 installed
import 'chart.js/auto'; // Import Chart.js
import './GroupView.css';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

const GroupView = () => {
  const [staffRoles, setStaffRoles] = useState([]);
  const [locations, setLocations] = useState([]);
  const [staffSickness, setStaffSickness] = useState([]);
  const [staffMembers, setStaffMembers] = useState([]);

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Adding month state
  const [roleColorMap, setRoleColorMap] = useState({});
  const logArray = useRef([]); // Use ref for log events array

  const [sicknessData, setSicknessData] = useState([]);
  const [overtimeData, setOvertimeData] = useState([]);
  const [payData, setPayData] = useState([]);

  // Fetch staff sickness filtered by startDate and endDate of the selected month
  const fetchStaffSickness = async () => {
    try {
      const startDate = new Date(selectedYear, selectedMonth - 1, 1).toISOString().split('T')[0];
      const endDate = new Date(selectedYear, selectedMonth, 0).toISOString().split('T')[0];

      const params = {
        startDate,
        endDate,
      };
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_sickness', { params });
      
      // Process the staff sickness data and aggregate by location_id and role
      const sicknessByLocationAndRole = [];
      response.data.forEach((entry) => {
        const { location_id, role, daysOff } = entry;
        const location = locations.find(loc => loc._id === location_id)?.name;
        const existingEntry = sicknessByLocationAndRole.find(item => item.location === location && item.role === role);
        if (existingEntry) {
          existingEntry.value += daysOff;
        } else {
          sicknessByLocationAndRole.push({ location, role, value: daysOff });
        }
      });

      setSicknessData(sicknessByLocationAndRole);
    } catch (error) {
      console.error('Error fetching staff sickness:', error);
    }
  };

  // Fetch all staff members
  const fetchStaffMembers = async () => {
    try {
      const staffUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/staff`;
      const staffResponse = await axios.get(staffUrl);
      setStaffMembers(staffResponse.data);
    } catch (error) {
      console.error('Failed to load staff members:', error);
    }
  };

  // Fetch locations
  const fetchLocations = async () => {
    try {
      const locationsUrl = 'https://heuristic-cray.194-76-27-167.plesk.page/api/locations';
      const response = await axios.get(locationsUrl);
      setLocations(response.data);
    } catch (error) {
      console.error('Failed to load locations:', error);
    }
  };

  // Fetch log events for the selected month and year, and filter for overtimehrs > 0
  const fetchLogEvents = async () => {
    try {
      const params = {
        month: selectedMonth,
        year: selectedYear,
        overtime: true, // Implies filter for overtimehrs > 0
      };
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_log_events', { params });
      logArray.current = response.data;

      // Process log events to sum overtimehrs by location and role
      const overtimeByLocationAndRole = [];
      logArray.current.forEach((entry) => {
        const { location_id, role, overtimehrs } = entry;
        const location = locations.find(loc => loc._id === location_id)?.name;
        const existingEntry = overtimeByLocationAndRole.find(item => item.location === location && item.role === role);
        if (existingEntry) {
          existingEntry.value += overtimehrs;
        } else {
          overtimeByLocationAndRole.push({ location, role, value: overtimehrs });
        }
      });

      setOvertimeData(overtimeByLocationAndRole);
    } catch (error) {
      console.error('Error fetching log events:', error);
    }
  };

  // Process staff members data to calculate pay cost by location and role
  const processPayData = () => {
    const payByLocationAndRole = [];
    staffMembers.forEach((member) => {
      const { default_location_id, role, hrlypayrate, contracted_hours } = member;
      const location = locations.find(loc => loc._id === default_location_id)?.name;
      const totalPay = hrlypayrate * contracted_hours;

      const existingEntry = payByLocationAndRole.find(item => item.location === location && item.role === role);
      if (existingEntry) {
        existingEntry.value += totalPay;
      } else {
        payByLocationAndRole.push({ location, role, value: totalPay });
      }
    });

    // Fetch performance indicator for each location and calculate final pay/1000
    payByLocationAndRole.forEach(async (entry) => {
      const perfIndResponse = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/perf_inds', {
        params: { createdMonth: selectedMonth, createdYear: selectedYear, location_id: entry.location_id }
      });
      const indicatorValue = perfIndResponse.data.indicatorValue;
      entry.value = entry.value / (indicatorValue / 1000);
    });

    setPayData(payByLocationAndRole);
  };

  // Fetch data when the component mounts or month/year changes
  useEffect(() => {
    fetchStaffRoles();
    fetchStaffMembers();
    fetchLocations();
  }, []);

  useEffect(() => {
    fetchLogEvents();
    fetchStaffSickness();
    processPayData();
  }, [selectedMonth, selectedYear]);

  const [currentDate, setCurrentDate] = useState(new Date()); // Initialize current date

  // Function to format the date for display
  const formatDate = (date) => {
    const options = { month: 'long', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  // Function to go to the previous month
  const handlePreviousMonth = () => {
    setCurrentDate((prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, 1));
    setSelectedMonth((prevMonth) => (prevMonth === 1 ? 12 : prevMonth - 1));
    if (selectedMonth === 1) setSelectedYear((prevYear) => prevYear - 1);
  };


  const fetchStaffRoles = async () => {
    try {
      const rolesUrl = 'https://heuristic-cray.194-76-27-167.plesk.page/api/staff_roles';
      const response = await axios.get(rolesUrl);
      const roleMap = response.data.reduce((map, role) => {
        const trimmedRole = role.role.trim();
        const color = role.colour;
        if (trimmedRole && color) {
          map[trimmedRole] = color;
        }
        return map;
      }, {});

      const trimmedRoles = response.data.map(role => role.role.trim());
      setStaffRoles(trimmedRoles);
    } catch (error) {
      console.error('Failed to load staff roles:', error);
    }
  };


  // Function to go to the next month
  const handleNextMonth = () => {
    setCurrentDate((prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1));
    setSelectedMonth((prevMonth) => (prevMonth === 12 ? 1 : prevMonth + 1));
    if (selectedMonth === 12) setSelectedYear((prevYear) => prevYear + 1);
  };

  // Data functions for the graphs
  const getSicknessData = () => {
    return {
      labels: sicknessData.map(item => `${item.location} (${item.role})`),
      datasets: [{
        label: 'Days Off',
        data: sicknessData.map(item => item.value),
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
      }],
    };
  };

  const getOvertimeData = () => {
    return {
      labels: overtimeData.map(item => `${item.location} (${item.role})`),
      datasets: [{
        label: 'Overtime Hours',
        data: overtimeData.map(item => item.value),
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
      }],
    };
  };

  const getCostData = () => {
    return {
      labels: payData.map(item => `${item.location} (${item.role})`),
      datasets: [{
        label: 'Cost / 1000',
        data: payData.map(item => item.value),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      }],
    };
  };

  return (
    <div>
      <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Group Overview</h2>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <button onClick={handlePreviousMonth}>Previous</button>
        <h3>{formatDate(currentDate)}</h3>
        <button onClick={handleNextMonth}>Next</button>
      </div>

      <div className="gra-container">
        <div className="graph-sickness">
          <h4>Sickness</h4>
          <Bar data={getSicknessData()} options={{ responsive: true }} />
        </div>
        <div className="graph-overtime">
          <h4>Overtime</h4>
          <Bar data={getOvertimeData()} options={{ responsive: true }} />
        </div>
        <div className="graph-cost">
          <h4>Cost</h4>
          <Bar data={getCostData()} options={{ responsive: true }} />
        </div>
      </div>
    </div>
  );
};

export default GroupView;
