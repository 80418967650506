import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
//import CalendarPopup from './CalendarPopup'; // Import the CalendarPopup component
import './timesheets.css';
import ReviewLogEvent from './reviewLogEvent.js';
import * as XLSX from 'xlsx';


const Timesheets = () => {
  const [selectedLocation, setSelectedLocation] = useState('');
  const [locations, setLocations] = useState([]);
  const [selectedMonthYear, setSelectedMonthYear] = useState('');
  const [selectedStaffId, setSelectedStaffId] = useState('');
  const [popupDetails, setPopupDetails] = useState(null); // State to control the popup
  const [monthDetails, setMonthDetails] = useState([]);
  const [monthSummary, setMonthSummary] = useState([]);
  const [uniqueMonths, setUniqueMonths] = useState([]);
  // Refs to store fetched data
  const staffMembersRef = useRef([]);
  const staffHolidaysRef = useRef([]);
  const staffSicknessRef = useRef([]);
  const logEventsRef = useRef([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showReviewDialog, setShowReviewDialog] = useState(false);
  const [selectedLogAnomaly, setSelectedLogAnomaly] = useState(null);
   
  const [selectedDate, setSelectedDate] = useState(null);
  // Fetch available locations
  const fetchLocations = useCallback(async () => {
    try {
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/locations');
      setLocations(response.data);
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  }, []);

  // Fetch staff members
  const fetchStaffMembers = useCallback(async () => {
    if (!selectedLocation) return;
    try {
      const params = {
        default_location_id: selectedLocation,
        active: true,
      };
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff', { params });
      staffMembersRef.current = response.data;
    } catch (error) {
      console.error('Error fetching staff members:', error);
    }
  }, [selectedLocation]);

  // Fetch staff holidays
  const fetchStaffHolidays = useCallback(async () => {
    if (!selectedLocation) return;
    try {
      const params = {
        organisation_id: selectedLocation,
      };
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_holiday', { params });
      staffHolidaysRef.current = response.data;
    } catch (error) {
      console.error('Error fetching staff holidays:', error);
    }
  }, [selectedLocation]);

  // Fetch staff sickness records
  const fetchStaffSickness = useCallback(async () => {
    if (!selectedLocation) return;
    try {
      const params = {
        organisation_id: selectedLocation,
      };
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_sickness', { params });
      staffSicknessRef.current = response.data;
    } catch (error) {
      console.error('Error fetching staff sickness:', error);
    }
  }, [selectedLocation]);

  // Fetch log events
  const fetchLogEvents = useCallback(async () => {
    if (!selectedLocation) return;
    try {
      const params = {
        location: selectedLocation,
      };
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_log_events_today', { params });
      logEventsRef.current = response.data;
    } catch (error) {
      console.error('Error fetching log events:', error);
    }
  }, [selectedLocation]);

  useEffect(() => {
    fetchLocations();
  }, []); // Empty dependency array to run only once on mount
  
  useEffect(() => {
    if (selectedLocation) {
      fetchStaffMembers();
      fetchStaffHolidays();
      fetchStaffSickness();
      fetchLogEvents().then(() => {
        // Update unique months based on log events
        const months = Array.from(
          new Set(
            logEventsRef.current.map((event) =>
              new Date(event.created).toLocaleString('default', { month: 'long', year: 'numeric' })
            )
          )
        );
        setUniqueMonths(months);
      });
    }
  }, [selectedLocation]);
  
  
  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
    setSelectedMonthYear('');
    setSelectedStaffId('');
    setMonthDetails([]);
    setMonthSummary([]);
  };

 
  const handleMonthYearClick = async (monthYear) => {
    setSelectedMonthYear(monthYear);
    const date = new Date(`1 ${monthYear}`);
    const selectedMonth = date.getMonth(); // 0-based index
    const selectedYear = date.getFullYear();
    await populateMonthDetails(selectedMonth, selectedYear);
  };

  const handleStaffClick = (staffId) => {
    setSelectedStaffId(staffId);
  };

 
  const formatTime = (dateString) => {
    const date = new Date(dateString);
    
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return ''; // Return an empty string for invalid dates
    }
    
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };
  
   
  const handleExport = async (monthYear) => {
    // Create monthSummary
//    setSelectedMonthYear(monthYear);
 //   const date = new Date(`1 ${monthYear}`);
 //   const selectedMonth = date.getMonth(); // 0-based index
 //   const selectedYear = date.getFullYear();
    const headers = ["Staff Name", "Role", "Std hrs worked", "Std pay", "Overtime hrs", "Overtime pay", "Days sick", "Days holiday"]; // Example headers
  
if (!monthSummary) return;
   
    const location = locations.find(loc => loc._id === selectedLocation);

    let locationName = 'Not found'; // Default value
    
    if (location) {
      locationName = location.name; // Extract the name field
    } else {
      console.log('Location not found');
    }
    
    // Create an array of arrays (row format) with headers and data
    const data = [
      [], // Blank line
      [{ v: `Site: ${locationName}`, s: { font: { bold: true, sz: 14 } } }], // Location name in bold and larger font
      [],
      [{ v: `Timesheet summary created on ${new Date().toLocaleString('en-GB', { timeZone: 'UTC' })} for: ${selectedMonthYear}`, s: { font: { sz: 12 } } }], // Summary line
      [],
      headers, // Add headers as the first row
      ...monthSummary.map((item) => [
        item.staff_name,
        item.role,
        item.totalStdHours,
        item.totalStdPay,
        item.totalOvertimeHours,
        item.totalOvertimePay,
        item.daysSick,
        item.daysHoliday
      ])
    ];
      
    // Calculate totals for each column
    const totals = {
     totalStdHours: monthSummary.reduce((sum, item) => sum + item.totalStdHours, 0),
     totalStdPay: monthSummary.reduce((sum, item) => sum + item.totalStdPay, 0),
      totalOvertimeHours: monthSummary.reduce((sum, item) => sum + item.totalOvertimeHours, 0),
      totalOvertimePay: monthSummary.reduce((sum, item) => sum + item.totalOvertimePay, 0),
      daysHoliday: monthSummary.reduce((sum, item) => sum + item.daysHoliday, 0),
      daysSick: monthSummary.reduce((sum, item) => sum + item.daysSick, 0),
    };
  
    // Add a blank line before the totals
    data.push([], [
     { v: 'Totals:', s: { font: { bold: true } } }, // 'Totals:' label in bold
      '',
      totals.totalStdHours,
     totals.totalStdPay,
     totals.totalOvertimeHours,
     totals.totalOvertimePay,
     totals.daysSick,
     totals.daysHoliday
   ]);
  
    // Create a new worksheet from the data
    const worksheet = XLSX.utils.aoa_to_sheet(data); // aoa_to_sheet converts an array of arrays to a worksheet
  
    // Optional: Customize column widths
    worksheet['!cols'] = [
      { wch: 25 }, // Width for Staff Name column
      { wch: 10 }, // Width for Role column
      { wch: 20 }, // Width for Std hrs worked column
      { wch: 20 }, // Width for Std pay column
      { wch: 20 }, // Width for Overtime hrs column
      { wch: 20 }, // Width for Overtime pay column
      { wch: 20 }, // Width for Days sick column
      { wch: 20 }, // Width for Days holiday column
    ];
  
    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Month Summary");
  
    // Export the workbook as an Excel file
    XLSX.writeFile(workbook, `MonthSummary_${monthYear}.xlsx`);
  };
  



  const handleExportDetails = async (monthYear) => {
   
    const headers = ["Date", "Type", "Log in/out","Break", "Login comment","Logout comment","Hrs planned", "Std. hrs worked", "Std hrs pay", "Overtime hrs", "Overtime pay", "Status","Std -O/t hrly rates"]; // Example headers

    
//    await populateMonthDetails(selectedMonth, selectedYear);
  
if (!monthDetails) return;

monthDetails
  .filter((detail) => detail.staff_id === selectedStaffId)
  .sort((a, b) => {
  const dateA = new Date(a.date);
  const dateB = new Date(b.date);
  return dateA - dateB; // Sort in ascending order
});

    const location = locations.find(loc => loc._id === selectedLocation);

    let locationName = 'Not found'; // Default value
    
    if (location) {
      locationName = location.name; // Extract the name field
    } else {
      console.log('Location not found');
    }
    // get staff name from the first item
    let staffdetails=monthDetails[0]["staff_name"] + " Role:" + monthDetails[0]["role"];
     
    // Create an array of arrays (row format) with headers and data
    const data = [
      [], // Blank line
      [{ v: `Site: ${locationName}`, s: { font: { bold: true, sz: 14 } } }], // Location name in bold and larger font
      [],
      [{ v: `Staff name: ${staffdetails}`, s: { font: { bold: true, sz: 14 } } }], // Staff name in bold and larger font
      [],
      [{ v: `Staff month detail created on ${new Date().toLocaleString('en-GB', { timeZone: 'UTC' })} for: ${selectedMonthYear}`, s: { font: { sz: 12 } } }], // Summary line
      [], // Blank line
      headers, // Add headers as the first row
      ...monthDetails.map((item) => [
       
        item.dayname + ' ' + new Date(item.date).getDate().toString().padStart(2, '0') + '/' + (new Date(item.date).getMonth() + 1).toString().padStart(2, '0'), // Format date as DD/M
        item.type,
        formatTime(item.actual_login) + "-" + formatTime(item.actual_logout),
        item.breaklength,
        item.login_comment,
        item.logout_comment,
        item.hrsPlanned,
        item.stdhrsworked,
        item.stdhrspay,
        item.overtimehrs,
        item.overtimepay,
        item.status,
        '£'+ item.stdhrspay + '- £' + item.overtimepay
      ])
    ];
    
    

    // Calculate totals for each column
    const totals = {
      totalStdHours: monthSummary.reduce((sum, item) => sum + item.stdhrsworked, 0),
      totalStdPay: monthSummary.reduce((sum, item) => sum + item.stdhrspay, 0),
      totalOvertimeHours: monthSummary.reduce((sum, item) => sum + item.overtimehrs, 0),
      totalOvertimePay: monthSummary.reduce((sum, item) => sum + item.overtimepay, 0),
     };
  
    // Add a blank line before the totals
 //  data.push([], [
  //    { v: 'Totals:', s: { font: { bold: true } } }, // 'Totals:' label in bold
   //   '','','','',
    //  totals.totalStdHours,
     // totals.totalStdPay,
     // '',
    //  totals.totalOvertimeHours,
    //  totals.totalOvertimePay,
    //     ]);
  
    // Create a new worksheet from the data
    const worksheet = XLSX.utils.aoa_to_sheet(data); // aoa_to_sheet converts an array of arrays to a worksheet
  
    // Optional: Customize column widths
    worksheet['!cols'] = [
      { wch: 15 }, // Width for Staff Name column
      { wch: 10 }, // Width for Role column
      { wch: 10 }, // Width for Std hrs worked column
      { wch: 20 }, // Width for Std pay column
      { wch: 20 }, // Width for Overtime hrs column
      { wch: 20 }, // Width for Overtime pay column
      { wch: 20 }, // Width for Days sick column
      { wch: 20 }, // Width for Days holiday column
      { wch: 20 }, // Width for Overtime hrs column
      { wch: 20 }, // Width for Overtime pay column
      { wch: 20 }, // Width for Days sick column
      { wch: 20 }, // Width for Days holiday column
   
    ];
  
    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Month Details");
  
    // Export the workbook as an Excel file
    XLSX.writeFile(workbook, `MonthSummary_${selectedMonthYear}.xlsx`);
  };
  



  const handleExportAllDetails = async (monthYear) => {
    const headers = ["Date", "Type", "Log in/out","Break", "Login comment","Logout comment","Hrs planned", "Std. hrs worked", "Std hrs pay", "Overtime hrs", "Overtime pay", "Status","Std - O/t hrly rates"]; // Example headers
  
    if (!monthDetails) return;
  
    // Step 1: Find all unique staff IDs in monthDetails
    const staffIds = [...new Set(monthDetails.map(detail => detail.staff_id))];
  
    // Step 2: Create a new workbook
    const workbook = XLSX.utils.book_new();
  
    // Step 3: Loop through each staff ID and generate their sheet
    staffIds.forEach((staffId) => {
      // Filter details for the current staff
      const staffDetails = monthDetails
        .filter((detail) => detail.staff_id === staffId)
        .sort((a, b) => new Date(a.date) - new Date(b.date)); // Sort by date
  
      if (staffDetails.length === 0) return; // Skip if no details for the staff
  
      // Get location and staff info
      const location = locations.find(loc => loc._id === selectedLocation);
      const locationName = location ? location.name : 'Not found';
      const staffHeader = `${staffDetails[0].staff_name} - Role: ${staffDetails[0].role}`;
  
  

      // Step 4: Prepare the data for this staff's sheet
      const data = [
        [], // Blank line
        [{ v: `Site: ${locationName}`, s: { font: { bold: true, sz: 14 } } }], // Location name
        [], // Blank line
        [{ v: `Staff name: ${staffHeader}`, s: { font: { bold: true, sz: 14 } } }], // Staff name
        [], // Blank line
        [{ v: `Staff month detail created on ${new Date().toLocaleString('en-GB', { timeZone: 'UTC' })} for: ${selectedMonthYear}`, s: { font: { sz: 12 } } }], // Summary line
        [], // Blank line
        headers, // Add headers as the first row
        ...staffDetails.map((item) => [
          `${item.dayname} ${new Date(item.date).getDate().toString().padStart(2, '0')}/${(new Date(item.date).getMonth() + 1).toString().padStart(2, '0')}`, // Date in DD/MM format
          item.type,
          formatTime(item.actual_login) + "-" + formatTime(item.actual_logout),
          item.breaklength,
          item.login_comment,
          item.logout_comment,
          item.hrsPlanned,
          item.stdhrsworked,
          item.stdhrspay,
          item.overtimehrs,
          item.overtimepay,
          item.status,
          '£'+ item.stdhrspay + '- £' + item.overtimepay
        ])
      ];
  
      // Step 5: Create a worksheet for this staff
      const worksheet = XLSX.utils.aoa_to_sheet(data);
  
      worksheet['!cols'] = [
        { wch: 15 }, // Width for Staff Name column
        { wch: 10 }, // Width for Role column
        { wch: 10 }, // Width for Std hrs worked column
        { wch: 20 }, // Width for Std pay column
        { wch: 20 }, // Width for Overtime hrs column
        { wch: 20 }, // Width for Overtime pay column
        { wch: 20 }, // Width for Days sick column
        { wch: 20 }, // Width for Days holiday column
        { wch: 20 }, // Width for Overtime hrs column
        { wch: 20 }, // Width for Overtime pay column
        { wch: 20 }, // Width for Days sick column
        { wch: 20 }, // Width for Days holiday column
     
      ];
    
    
      // Step 6: Append this worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, staffDetails[0].staff_name); // Use staff name as sheet name
    });
  
    // Step 7: Export the workbook as an Excel file
    XLSX.writeFile(workbook, `MonthDetails_${monthYear}.xlsx`);
  };
  


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString('en-GB', { weekday: 'short', day: 'numeric' })}`;
  };

  const formatDateTime = (input) => {
    if (!input) return 'Invalid Date';
    const date = new Date(input);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    return date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  };

  const editSummaryItem = (ind) => {
    // You can now use the 'ind' variable which represents the index of the monthSummary array
    console.log("Editing item at index:", ind);
    
    // Add your logic here to handle the editing of the summary item
  };
    const handleDialogClose = () => {
    setShowReviewDialog(false);
    // Logic to refresh the logAnomalies grid if needed
     
  };


  const openLogEventReview = async (anomaly) => {
    if (anomaly == null || anomaly=='') {
      return;
    }
  
    // Get log event and load into anomaly
    try {
      const params = {
        itemid: anomaly,
      };
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_log_events', { params });
  
      // Check if data exists and has at least one item
      if (response.data && response.data.length > 0) {
        // Extract the first item from the response data

        const logEvent = response.data[0];

         
      
        // Store the selected log anomaly in the state
        setSelectedLogAnomaly(logEvent);
        
        // Trigger the dialog to open
        setShowReviewDialog(true);
      }
    } catch (error) {
      console.error("Error fetching log event:", error); // Optionally handle errors
    }
  };
  
  
 
 // Function to populate monthDetails
const populateMonthDetails = (selectedMonth, selectedYear) => {
  return new Promise((resolve) => {
    let monthDetailsArray = [];

    // Get staff members
    const staffMembers = staffMembersRef.current;

    // Cycle through each staff member
    staffMembers.forEach((staffMember) => {
      const staff_id = staffMember._id;
      const role = staffMember.role;
      const hrsmth = staffMember.contracted_hours_per_month;
      const staff_name = `${staffMember.firstName} ${staffMember.lastName}`;
      const hourlyPayRate = staffMember.hourlyPayRate;
      const dailyEntries = staffMember.dailyEntries;

      // Days in the selected month
      const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();

      // Cycle through each day of the month
      for (let day = 1; day <= daysInMonth; day++) {
        const date = new Date(selectedYear, selectedMonth, day);
        const dayname = date.toLocaleString('en-GB', { weekday: 'short' }); // 'Mon', 'Tue', etc.
        let weekNumber = Math.ceil(day / 7);
        if (weekNumber > 4) weekNumber = 1; // If week 5, call it week 1

        const dailyEntry = dailyEntries.find(
          (entry) => entry.dayOfWeek === dayname && entry.weekNumber === weekNumber
        );

        let type = 'Not scheduled';
        let hrsPlanned = 0;
        if (dailyEntry && dailyEntry.hoursworked > 0) {
          type = 'Scheduled';
          hrsPlanned = dailyEntry.hoursworked;
        }

        monthDetailsArray.push({
          staff_name,
          role,
          hourlyPayRate,
          hrsmth,
          staff_id,
          date: date.toISOString().split('T')[0],
          dayname,
          type,
          hrsPlanned,
          stdhrsworked: 0,
          stdhrspay: 0,
          overtimehrs: 0,
          overtimepay: 0,
          payadjust: 0,
          comment: "",
          itemid: "",
          status: "",
        });
      }
    });

    // Process staff sickness
    const staffSickness = staffSicknessRef.current;
    staffSickness.forEach((sickness) => {
      const { staff_id, created, rtw_date } = sickness;
      if (!rtw_date) return; // Skip if rtw_date is null or empty
      const sicknessStart = new Date(created);
      const sicknessEnd = new Date(rtw_date);

      for (let d = new Date(sicknessStart); d <= sicknessEnd; d.setDate(d.getDate() + 1)) {
        if (d.getMonth() === selectedMonth && d.getFullYear() === selectedYear) {
          const dateStr = d.toISOString().split('T')[0];
          const monthDetail = monthDetailsArray.find(
            (md) => md.staff_id === staff_id && md.date === dateStr
          );
          if (monthDetail) {
            monthDetail.type = 'Sickness';
          }
        }
      }
    });

    // Process staff holidays
    const staffHolidays = staffHolidaysRef.current;
    staffHolidays.forEach((holiday) => {
      const { staff_id, start, end, hourlyPayRate, stdhrsworked, unpaid } = holiday;
      const holidayStart = new Date(start);
      const holidayEnd = new Date(end);

      for (let d = new Date(holidayStart); d <= holidayEnd; d.setDate(d.getDate() + 1)) {
        if (d.getMonth() === selectedMonth && d.getFullYear() === selectedYear) {
          const dateStr = d.toISOString().split('T')[0];
          const monthDetail = monthDetailsArray.find(
            (md) => md.staff_id === staff_id && md.date === dateStr
          );
          if (monthDetail && monthDetail.type === 'Scheduled') {
            if (unpaid===true){
              monthDetail.type= 'Holiday - unpaid';
              monthDetails.stdhrspay=0;
            }
            else {
            monthDetail.type = 'Holiday';
            monthDetail.stdhrsworked = monthDetail.hrsPlanned;
            monthDetail.stdhrspay = monthDetail.hrsPlanned * monthDetail.hourlyPayRate;
            }
          }
        }
      }
    });

    // Process log events
    const logEvents = logEventsRef.current.filter((event) => {
      const eventDate = new Date(event.created);
      return eventDate.getMonth() === selectedMonth && eventDate.getFullYear() === selectedYear;
    });

    logEvents.forEach((logEvent) => {
      const {breaklength,actual_login, actual_logout, login_comment,logout_comment, _id, staff_id, staff_name, status, created, stdhrsworked, stdhrspay, overtimehrs, overtimepay } = logEvent;
      const dateStr = new Date(created).toISOString().split('T')[0];
      const monthDetail = monthDetailsArray.find(
        (md) => md.staff_id === staff_id && md.date === dateStr
      );

      if (monthDetail && monthDetail.type !== 'Worked' && monthDetail.type !== 'Extra Session') {
        monthDetail.type = 'Worked';
        monthDetail.stdhrsworked = stdhrsworked;
        monthDetail.stdhrspay = stdhrsworked * stdhrspay;
        monthDetail.overtimehrs = overtimehrs;
        monthDetail.overtimepay = overtimehrs * overtimepay;
        monthDetail.itemid = _id;
        monthDetail.status = status;
        monthDetail.actual_login=actual_login;
        monthDetail.actual_logout=actual_logout;
        monthDetail.login_comment=login_comment;
        monthDetail.logout_comment=logout_comment;
        monthDetail.breaklength=breaklength;
      } else if (!monthDetail) {
        const dayname = new Date(created).toLocaleString('en-GB', { weekday: 'short' });
        monthDetailsArray.push({
          staff_name,
          staff_id,
          date: dateStr,
          dayname,
          itemid: _id,
          type: 'Extra Session',
          stdhrsworked,
          stdhrspay: stdhrsworked * stdhrspay,
          overtimehrs,
          overtimepay: overtimehrs * overtimepay,
          actual_login,
          actual_logout,
          login_comment,
          logout_comment,
          breaklength,
          status
  
        });
      }
    });

    monthDetailsArray = monthDetailsArray.filter(entry => entry.type !== 'Not scheduled');

    // Update state
    setMonthDetails(monthDetailsArray);

    // Call populateMonthSummary and resolve the promise after updating the state
    populateMonthSummary(monthDetailsArray);
    
    // Resolve the promise to indicate that the function has completed
    resolve();
  });
};

 
  // Function to populate monthSummary
  const populateMonthSummary = (monthDetailsArray) => {
    const summary = {};

    monthDetailsArray.forEach((detail) => {
      const {
        staff_id,
        staff_name,
        role,
        hrsmth,
        stdhrsworked,
        stdhrspay,
        overtimehrs,
        overtimepay,
        type,
      } = detail;
      if (!summary[staff_id]) {
        summary[staff_id] = {
          staff_id,
          staff_name,
          role,
          hrsmth,
          totalStdHours: 0,
          totalStdPay: 0,
          totalOvertimeHours: 0,
          totalOvertimePay: 0,
          daysHoliday: 0,
          daysSick: 0,
        };
      }
      summary[staff_id].totalStdHours += stdhrsworked || 0;
      summary[staff_id].totalStdPay += stdhrspay || 0;
      summary[staff_id].totalOvertimeHours += overtimehrs || 0;
      summary[staff_id].totalOvertimePay += overtimepay || 0;
      if (type === 'Holiday') {
        summary[staff_id].daysHoliday += 1;
      }
      if (type === 'Sickness') {
        summary[staff_id].daysSick += 1;
      }
    });

    const monthSummaryArray = Object.values(summary).sort((a, b) =>
      a.staff_name.localeCompare(b.staff_name)
    );

    setMonthSummary(monthSummaryArray);
  };

  return (
    <div>
      <div>
        <h1 style={{ display: 'inline', marginRight: '10px', fontSize: '18px' }}>Timesheets for</h1>
        <div style={{ display: 'inline-block' }}>
          <select 
            onChange={handleLocationChange}
            style={{
              fontSize: '18px',
              height: '28px',
              padding: '4px',
            }}
          >
            <option value="">Select Location</option>
            {locations.map((location) => (
              <option key={location._id} value={location._id}>
                {location.name}
              </option>
            ))}
          </select>
        </div>
      </div>
  
      <div className="log-events-container">
        {/* Left Panel: Months */}
<div className="log-event-column">
  <h2>Months</h2>
  {uniqueMonths.map((monthYear) => (
    <div
      key={monthYear}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '5px',
        backgroundColor: selectedMonthYear === monthYear ? 'lightblue' : 'transparent'
      }}
    >
      <div
        onClick={() => handleMonthYearClick(monthYear)}
        style={{ cursor: 'pointer', flexGrow: 1 }}
      >
        {monthYear}
      </div>
    </div>
  ))}
</div>

{/* Middle Panel: Month Summary */}
<div className="month-summary-column">
  {/* Export buttons at the top right */}
  <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
    <button onClick={() => handleExport(selectedMonthYear)}>
      Export Summary to Excel
    </button>
    <button onClick={() => handleExportAllDetails()} style={{ marginLeft: '10px' }}>
      Export all details to Excel
    </button>
  </div>

  <h2>Staff Summary</h2>
  {monthSummary.length > 0 ? (
    monthSummary.map((summary) => (
      <div
        key={summary.staff_id}
        onClick={() => handleStaffClick(summary.staff_id)}
        style={{
          padding: '10px',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: selectedStaffId === summary.staff_id ? 'lightblue' : 'transparent'
        }}
      >
        <div className="staff-summary">
          <div className="staff-name">{summary.staff_name}</div>
          <div className="staff-role">{summary.role}</div>
          <div className="summary-std-hours">
            Cont:  £{summary.totalStdPay.toFixed(2)} ({summary.totalStdHours}hrs/{summary.hrsmth}hrs)
          </div>
          {summary.totalOvertimeHours > 0 && (
            <div className="summary-overtime">
              O/T: {summary.totalOvertimeHours}hrs £{summary.totalOvertimePay.toFixed(2)}
            </div>
          )}
          <div className="summary-sick-holiday">
            {summary.daysSick > 0 && <div>Sick: {summary.daysSick}</div>}
            {summary.daysHoliday > 0 && <div>Hol: {summary.daysHoliday}</div>}
          </div>
        </div>
      </div>
    ))
  ) : (
    <div>No data available for this month/year.</div>
  )}
</div>


    
        {/* Right Panel: Month Details for selected staff */}
        <div className="month-details-column">
          {/* Export to Excel button at the top right of the Staff Details panel */}
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
            <button onClick={handleExportDetails}>
              Export details to Excel
            </button>
          </div>
  
          <h2>Staff Details</h2>
          {selectedStaffId && selectedMonthYear && (
            <div>
              {monthDetails
                .filter((detail) => detail.staff_id === selectedStaffId)
                .sort((a, b) => new Date(a.date) - new Date(b.date))
                .map((detail) => {
                  let backgroundColor = 'transparent';
  
                  if (detail.type === 'Worked') {
                    if (detail.status === 'Completed') {
                      backgroundColor = 'lightgreen';
                    } else {
                      backgroundColor = 'lightcoral';
                    }
                  }
  
                  return (
                    <div
                      key={detail.date}
                      style={{ display: 'flex', padding: '5px', backgroundColor }}
                      onClick={() => openLogEventReview(detail.itemid)}
                    >
                      <div className="item-section day">{detail.dayname}, {new Date(detail.date).getDate()}</div>
                      <div className="item-section type">{detail.type}</div>
                      {detail.stdhrspay !== 0 && (
                        <div className="item-section stdpay">
                          Std Pay: £{detail.stdhrspay.toFixed(2)} Hours: {detail.stdhrsworked} / {detail.hrsPlanned}
                        </div>
                      )}
                      {detail.overtimepay > 0 && (
                        <div className="item-section overtime">
                          Overtime Pay: £{detail.overtimepay.toFixed(2)} O/t hrs: {detail.overtimehrs}
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
  
      {/* Popup for displaying review log event when a log event is clicked */}
      {showReviewDialog && (
        <ReviewLogEvent
          isOpen={showReviewDialog}
          onClose={handleDialogClose}
          logEvent={selectedLogAnomaly}
        />
      )}
    </div>
  );
}  
export default Timesheets;
