import React, { useState, useEffect, useRef } from 'react';
import './PlanningDialog.css';
import axios from 'axios';
import InputMask from 'react-input-mask'; // For input mask
import dayjs from 'dayjs';

const PlanningDialog = ({ data, onClose, workingLimits, selectedLocation, staffMembers }) => {
  const [entries, setEntries] = useState([]);
  const [dialogDate, setDialogDate] = useState(data?.date || '');
  const [locums, setLocums] = useState([]);
  const [locations, setLocations] = useState([]);
  const [staffRoles, setStaffRoles] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const dayOfWeek = useRef([]);

  const dialogRef = useRef(null);

  useEffect(() => {
    if (data?.date){
       setDialogDate(data.date);
      // Assuming date.date is a valid date string or Date object
const dateObject = new Date(data.date); // Create a Date object from the date
const options = { weekday: 'short' }; // Specify options for short weekday names
dayOfWeek.current = dateObject.toLocaleDateString('en-US', options); // Get the abbreviated day name

// Now dayOfWeek will be 'Mon', 'Tue', etc.

      }
    const fetchData = async () => {
      try {
        const holidaysData = await fetchStaffHolidays(dialogDate);
        await fetchLocums(dialogDate); // Pass dialogDate to fetchLocums
        await fetchStaffRoles();
        await fetchStaffList();
        const staffArray = staffMembers.map((member) => ({
          name: `${member.firstName} ${member.lastName}`,
          role: member.role,
          dailyEntries: member.dailyEntries,
          startEnd: '',
          hoursWorked: 0,
        }));

      
        const weekNumber = getWeekNumber(dialogDate);

        for (let i = staffArray.length - 1; i >= 0; i--) {
          const staff = staffArray[i];
          const dailyEntry = staff.dailyEntries?.find(
            (entry) =>
              entry.weekNumber === weekNumber &&
              entry.dayOfWeek === dayOfWeek.current  &&
              entry.startTime!=="00:00"
          );

          if (dailyEntry) {
            staff.startEnd = `${dailyEntry.startTime}-${dailyEntry.endTime}`;
            staff.hoursWorked = dailyEntry.hoursworked;
          } else {
            staffArray.splice(i, 1);
          }

          delete staff.dailyEntries;
        }

        staffArray.forEach((staff) => {
          const holiday = holidaysData.find((holiday) => holiday.staff_id === staff._id);
          if (holiday) {
            staff.startEnd = '';
            staff.hoursWorked = 0;
            staff.status = 'HOLIDAY';
          }
        });

        staffArray.sort((a, b) => {
          if (a.role !== b.role) return a.role.localeCompare(b.role);
          return a.name.localeCompare(b.name);
        });

        const groupedEntries = [];
        const roleTotals = {};
        let currentRole = '';

        staffArray.forEach((staff) => {
          if (staff.role !== currentRole) {
            if (currentRole !== '') {
              groupedEntries.push({ isTotal: true, role: currentRole, hoursWorked: roleTotals[currentRole] });
              groupedEntries.push({ isDivider: true });
            }
            currentRole = staff.role;
            roleTotals[currentRole] = 0;
          }

          roleTotals[staff.role] += staff.hoursWorked;
          groupedEntries.push(staff);
        });

        if (currentRole !== '') {
          groupedEntries.push({ isTotal: true, role: currentRole, hoursWorked: roleTotals[currentRole] });
          groupedEntries.push({ isDivider: true });
        }

        setEntries(groupedEntries);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [data, dialogDate, selectedLocation, staffMembers]);

  const fetchLocations = async () => {
    try {
      const locationsUrl = 'https://heuristic-cray.194-76-27-167.plesk.page/api/locations';
      const response = await axios.get(locationsUrl);
      setLocations(response.data);
    } catch (error) {
      console.error('Failed to load locations:', error);
    }
  };

  const fetchStaffRoles = async () => {
    try {
      const rolesUrl = 'https://heuristic-cray.194-76-27-167.plesk.page/api/staff_roles';
      const response = await axios.get(rolesUrl);
      const roleMap = response.data.reduce((map, role) => {
        const trimmedRole = role.role.trim();
        const color = role.colour;
        if (trimmedRole && color) {
          map[trimmedRole] = color;
        }
        return map;
      }, {});

      const trimmedRoles = response.data.map(role => role.role.trim());
      setStaffRoles(trimmedRoles);
    } catch (error) {
      console.error('Failed to load staff roles:', error);
    }
  };


 
  const fetchStaffHolidays = async (dialogDate) => {
    try {
      const response = await axios.get(
        'https://heuristic-cray.194-76-27-167.plesk.page/api/staff_holiday_daterange?startDate=' + dialogDate + '&endDate=' + dialogDate
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching staff holidays:', error);
      throw error;
    }
  };

  const getStaffOptions = () => {
    return staffList.map((staff) => ({
      value: `${staff.firstName} ${staff.lastName} (${staff.role})`,
      label: `${staff.firstName} ${staff.lastName} (${staff.role})`
    }));
  };
  

  const fetchStaffList = async () => {
    try {
      const staffUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/staff?default_location_id=${encodeURIComponent(selectedLocation)}`;
      const response = await axios.get(staffUrl);    
       setStaffList(response.data);
    } catch (error) {
      console.error('Error fetching staff:', error);
    }
  };


  const fetchLocums = async (dialogDate) => {
    try {
      const response = await fetch(`/api/locums?location=${selectedLocation}`);
      const data = await response.json();
      const filtered = data.filter((locum) => new Date(locum.start) >= new Date(dialogDate));
      setLocums(filtered);
    } catch (error) {
      console.error('Error fetching locums:', error);
    }
  };

  const handleLocumChange = (index, field, value) => {
    const updatedLocums = [...locums];
    if (field=='name')
    updatedLocums[index][field] = value;
    setLocums(updatedLocums);
  };

  const saveLocumChanges = async () => {
    console.log('Saving locum changes...');
    await axios.post('/api/saveLocums', locums);
  };

  const cancelLocumChanges = () => {
    fetchLocums(dialogDate); // Fetch again to reset locums
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const fromIndex = parseInt(e.dataTransfer.getData('text/plain'), 10);
    const toIndex = parseInt(e.target.dataset.index, 10);

    if (fromIndex === toIndex) return;

    const updatedLocums = [...locums];
    const [movedLocum] = updatedLocums.splice(fromIndex, 1);
    updatedLocums.splice(toIndex, 0, movedLocum);

    setLocums(updatedLocums);
  };


  const toDateInputValue = (date) => {
    if (!date) return ''; // handle cases where the date is null/undefined
    const d = new Date(date);
    return d.toISOString().substring(0, 10); // format as YYYY-MM-DD
  };
  

  // Helper functions
  const toDateInputValues = (date) => {
    if (!(date instanceof Date)) return '';

    const adjustedDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

    const day = adjustedDate.getDate().toString().padStart(2, '0');
    const month = (adjustedDate.getMonth() + 1).toString().padStart(2, '0');
    const year = adjustedDate.getFullYear();

    return `${day}/${month}/${year}`;  // Format as dd/mm/yyyy
  };

  const addNewLocum = (setLocums) => {
    setLocums((prevLocums) => [
      ...prevLocums,
      { name: '', role: '', start: '', end: '', startTime: '', endTime: '', comment: '', isNew: true }
    ]);
  };

 
  const getWeekNumber = (dateString) => {
    const date = new Date(dateString);
    const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
    return Math.ceil((date.getDate() + startDate.getDay()) / 7) > 4 ? 1 : Math.ceil((date.getDate() + startDate.getDay()) / 7);
  };

  const getWorkingLimit = (role, type) => {
    if (!workingLimits) return 0; // Return 0 if workingLimits is not provided

    // Create the concatenated key using role and dayOfWeek
    const key = `${role}-${dayOfWeek.current}`;

    // Find the limit in workingLimits using the key
    const limit = workingLimits[key];

    // Check the type and return the appropriate value
    if (type === 'minimum') {
        return limit ? limit : 0; // Return the limit or 0 if it doesn't exist
    } else if (type === 'optimum') {
        return limit ? limit * 1.5 : 0; // Return 1.5 times the limit or 0 if it doesn't exist
    }
    
    return 0; // Default return if type is not recognized
};


// Function to format the date
const formatDate = (isoString) => {
  return dayjs(isoString).format('DD/MM/YY');
};

  const getTotalRowStyles = (total, role) => {
    const minimum = getWorkingLimit(role, 'minimum');
    const optimum = getWorkingLimit(role, 'optimum');

    if (total < minimum) {
      return { backgroundColor: 'red', color: 'white' };
    } 
    if (total >= minimum && total < optimum) {
      return { backgroundColor: 'lightgreen', color: 'black' };
    }
   if (total >=  optimum) {
      return { backgroundColor: 'darkgreen', color: 'white' };
    }

//    return { backgroundColor: 'darkgreen', color: 'white' };
  };

  const handleClickSave = () => {
    saveLocumChanges();
  };

  const handleClickCancel = () => {
    cancelLocumChanges();
  };

    return (
    <div className="planning-dialog" ref={dialogRef}>
      <button className="close-button" onClick={onClose}>×</button>
      <h2>Planning for {formatDate(dialogDate)}</h2>
      
      <div className="planning-container">
        {/* Left Panel */}
        <div className="left-panel">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Role</th>
                <th>Start-End</th>
                <th>Hours Worked</th>
              </tr>
            </thead>
            <tbody>
              {entries.map((entry, index) => {
                if (entry.isTotal) {
                  return (
<tr key={index} style={getTotalRowStyles(entry.hoursWorked, entry.role)}>
  <td colSpan="3">Total for {entry.role}</td>
  <td>{entry.hoursWorked}</td>
  <td>
    Min: {getWorkingLimit(entry.role, "minimum")} Opt: {getWorkingLimit(entry.role, "optimum")}
  </td>
</tr>




                  );
                }

                if (entry.isDivider) {
                  return (
                    <tr key={index} className="divider">
                      <td colSpan="4"></td>
                    </tr>
                  );
                }

                return (
                  <tr
                    key={index}
                    draggable
                    onDragStart={(e) => handleDragStart(e, index)}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    data-index={index}
                  >
                    <td>{entry.name}</td>
                    <td>{entry.role}</td>
                    <td>{entry.startEnd}</td>
                    <td>{entry.hoursWorked}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="right-panel">
  <h3>Locums</h3>
  <button onClick={() => addNewLocum(setLocums)}>Add New Locum</button>
  <div className="locum-table">
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Role</th>
          <th>Start Date</th>
          <th>End Date</th>
        </tr>
        <tr>
          <th>Start Time</th>
          <th>End Time</th>
          <th>Comment</th>
        </tr>
      </thead>
      <tbody>
        {locums.map((locum, index) => (
          <tr
            key={index}
            draggable
            onDragStart={(e) => handleDragStart(e, index)}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            data-index={index}
          >
            <td>\
              <select
                value={locum.name}
                onChange={(e) => handleLocumChange(index, 'name', e.target.value)}
              >
                <option value="">Select Staff</option>
                {getStaffOptions().map((option, i) => (
                  <option key={i} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </td>
             <td>
              <input
                type="date"
                value={toDateInputValue(locum.start)}
                onChange={(e) => handleLocumChange(index, 'start', e.target.value)}
              />
            </td>
            <td>
              <input
                type="date"
                value={toDateInputValue(locum.end)}
                onChange={(e) => handleLocumChange(index, 'end', e.target.value)}
              />
            </td>
            <td>
              <InputMask
                mask="99:99"
                value={locum.startTime}
                onChange={(e) => handleLocumChange(index, 'startTime', e.target.value)}
                maskChar={null}
              />
            </td>
            <td>
              <InputMask
                mask="99:99"
                value={locum.endTime}
                onChange={(e) => handleLocumChange(index, 'endTime', e.target.value)}
                maskChar={null}
              />
            </td>
            <td>
              <input
                type="text"
                value={locum.comment || ''}
                onChange={(e) => handleLocumChange(index, 'comment', e.target.value)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  <button onClick={saveLocumChanges}>Save Changes</button>
  <button onClick={cancelLocumChanges}>Cancel</button>
</div>

      </div>
    </div>
  );
};
export default PlanningDialog;