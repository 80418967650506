import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Homepage.css';
import ReviewHoliday from './reviewHoliday'; 

import  { useRef } from 'react';
import ReviewLogEvent from './reviewLogEvent.js';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const Homepage = () => {
  const [perfInds, setPerfInds] = useState([]);
  const [perfIndsGraph, setPerfIndsGraph] = useState([]);
  const [selectedHolidayId, setSelectedHolidayId] = useState(null); // State to track which holiday is selected
  const [refreshKey, setRefreshKey] = useState(0);  // State for forcing a re-render

    const handleRefresh = () => {
        setRefreshKey((prevKey) => prevKey + 1);  // Increment the refresh key to trigger a re-render
    };
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [newIndDate, setNewIndDate] = useState('');
  const [newIndValue, setNewIndValue] = useState('');
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [editingInd, setEditingInd] = useState(null);
  const [staffHolidays, setStaffHolidays] = useState([]);
  const [staffCurrentHolidays, setStaffCurrentHolidays] = useState([]);
  const [logAnomalies, setLogAnomalies] = useState([]);
  const [showReviewDialog, setShowReviewDialog] = useState(false);
  const [selectedLogAnomaly, setSelectedLogAnomaly] = useState(null);

  const [staffSickness, setStaffSickness] = useState([]);
  const [staffMembers, setStaffMembers] = useState([]);
  const [logArraySet, setLogArraySet] = useState([]);
 
const logArray = useRef([]); // Step 1: Initialize logArray as a useRef
const [showHolidayDialog, setShowHolidayDialog] = useState(false);
  const [selectedHoliday, setSelectedHoliday] = useState(null);
  const [managerComment, setManagerComment] = useState('');
  const [holidayStatus, setHolidayStatus] = useState('Requested');

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Step 1: Fetch locations first

        await fetchLocations();
        await fetchAnomalies();
        if (selectedLocation) {
          // Step 2: Fetch other data
          await fetchPerfInds();
          await fetchStaffHolidays();
          await fetchCurrentHolidays();
          await fetchStaffSickness();
  
          // Step 3: Fetch log events (sets logArray)
          await fetchLogEvents(); // Wait for logArray to be set
  
          // Step 4: Modify logArray via fetchStaffMembers and trigger re-render
        //  await fetchStaffMembers(); // Modify logArray using setLogArray
  
          // You don't need to manually force a re-render here. React will do this
          // automatically when logArray is updated using setLogArray.
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
    const intervalId = setInterval(fetchData, 120000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);

  }, [refreshKey,selectedLocation]);  // Only run when selectedLocation changes
      
 
  const fetchLocations = async () => {
    try {
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/locations');
      setLocations(response.data);
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  const fetchPerfInds = async () => {
    try {
      const params = selectedLocation ? { locationId: selectedLocation } : {};
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/perfInd', { params });
  
      // Sort the data by date in reverse chronological order
      const sortedPerfInds = response.data.sort((a, b) => {
        const dateA = new Date(a.created).getTime();
        const dateB = new Date(b.created).getTime();
        return dateB - dateA; // Reverse chronological order based on 'created' field
      });
      
      setPerfInds(sortedPerfInds);
    
     // Sort a new array for the graph in chronological order
     const perfIndsGraph = [...response.data].sort((a, b) => {
      const dateA = new Date(a.created).getTime();
      const dateB = new Date(b.created).getTime();
      return dateA - dateB; // Chronological order based on 'created' field
    });

    // Save this sorted array for the graph
    setPerfIndsGraph(perfIndsGraph);
    
    } catch (error) {
      console.error('Error fetching performance indicators:', error);
    }
  };
  

  const fetchStaffHolidays = async () => {
    try {
      const params = {
        organisation_id: selectedLocation,
        status: "Requested"
      };
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_holiday', { params });
      setStaffHolidays(response.data);
    } catch (error) {
      console.error('Error fetching staff holidays:', error);
    }
  };


  const refreshLogAnomalies = () => {
   fetchAnomalies();
  };

  const fetchCurrentHolidays = async () => {
    const today = new Date().toISOString().split('T')[0];
     try {
      const params = {
        organisation_id: selectedLocation,
        startDate: today,
        endDate: today,
        status: "Approved"
      };
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_holiday', { params });
      setStaffCurrentHolidays(response.data);
    } catch (error) {
      console.error('Error fetching staff holidays:', error);
    }
  };


  const openLogEventReview = (anomaly) => {
    setSelectedLogAnomaly(anomaly);  // This will store the selected anomaly in the state
    setShowReviewDialog(true);       // This will trigger the dialog to open
  };
  

  const handleDialogClose = () => {
    setShowReviewDialog(false);
    // Logic to refresh the logAnomalies grid if needed
    refreshLogAnomalies(); // Refresh function
  };

  const fetchStaffSickness = async () => {
    try {
      const params = {
        organisation_id: selectedLocation,
        status: 'Open'
      };
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_sickness', { params });
      setStaffSickness(response.data);
    } catch (error) {
      console.error('Error fetching staff sickness:', error);
    }
  };

  const formatAsTime = (dateString) => {
    const date = new Date(dateString);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

 // const fetchStaffMembers = async () => {
    
 // };
    
  const fetchLogEvents = async () => {
    setLogArraySet([]);

    try {
      const today = new Date().toISOString().split('T')[0];
    const params = {
       location: selectedLocation, //,
    //   log_status: 'Logged in',  //,
        date: today
      };
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_log_events_today', { params });
      logArray.current = response.data; // Assign response.data to the current value of logArray

    } catch (error) {
      console.error('Error fetching log events:', error);
    }



    try {
      const params = { default_location_id: selectedLocation };
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff', { params });
      let members = response.data;
  
      const today = new Date();
      const dayOfWeek = today.toLocaleString('en-GB', { weekday: 'short' });
      const weekNumber = Math.ceil(today.getDate() / 7) % 5 || 1;
  
      const newEntries = [];
  
      members = members.map(member => {
        const staffId = member._id;
  
        const isOnHoliday = staffCurrentHolidays.some(holiday => holiday.staff_id === staffId);
        const isSick = staffSickness.some(sickness => sickness.staff_id === staffId);
  
        if (isOnHoliday || isSick) {
          return member; 
        }
  
        const logEntry = logArray.current.find(log => log.staff_id === staffId); // Use logArray from refs
        let eventtype = '';
  
        if (logEntry) {
          if (logEntry.log_status === "Logged in") {
            eventtype = `Arrived at ${formatAsTime(logEntry.actual_login)} - ${logEntry.planned_logout}`;
          } else {
            eventtype = `Left at ${formatAsTime(logEntry.actual_logout)}`;
          }
          const event = {
            staff_name: `${member.firstName} ${member.lastName} (${member.role} )`,
            event_type: eventtype,
            planned_login: '',
            staff_id: staffId
          };
          newEntries.push(event);
        } else {
          const dayEntry = member.dailyEntries.find(
            entry => entry.dayOfWeek === dayOfWeek && entry.weekNumber === weekNumber
          );
  
          if (dayEntry && dayEntry.startTime !== '00:00') {
            const event = {
              staff_name: `${member.firstName} ${member.lastName} (${member.role} )`,
              event_type: `Due in ${dayEntry.startTime}`,
              planned_login: dayEntry.startTime,
              staff_id: staffId
            };
            newEntries.push(event);
          }
        }
  
        return member;
      });
  
      setLogArraySet(prevLogArraySet => [...prevLogArraySet, ...newEntries]);

      //logArray.current = [...logArray.current, ...newEntries]; // Update logArray using refs
    } catch (error) {
      console.error('Error fetching staff members:', error);
    }


  };


  const fetchAnomalies = async () => {
    try {
      const params = {
        location: selectedLocation,
 //       date: new Date().toISOString().split('T')[0] // Use today's date if needed
      };
  
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_log_events_anomalies', { params });
      setLogAnomalies(response.data);
    } catch (error) {
      console.error('Error fetching log events:', error);
    }
  };
  

  const handleLocationChange = (e) => {
    setSelectedLocation(e.target.value);
  };

  const formatDate = (input) => {
    if (!input) return 'Invalid Date';
    const date = (typeof input === 'string') ? new Date(input) : input;
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    });
  };

  const openHolidayDialog = (holidayId) => {
    setSelectedHolidayId(holidayId); // Set the selected holiday ID
};

// Function to close the popup
const closeReviewHolidayPopup = () => {
    setSelectedHolidayId(null); // Reset the holiday ID to close the popup
    handleRefresh();
};
 

  const openAddDialog = (ind = null) => {
    if (ind) {
      setNewIndDate(ind.date);
      setNewIndValue(ind.value);
      setEditingInd(ind);
    } else {
      setNewIndDate('');
      setNewIndValue('');
      setEditingInd(null);
    }
    setShowAddDialog(true);
  };

  const handleSaveNewInd = async () => {
    try {
      const newPerformanceIndicator = {
        created: newIndDate,
        value: newIndValue,
        locationId: selectedLocation,
      };
      await axios.post('https://heuristic-cray.194-76-27-167.plesk.page/api/perfInd', newPerformanceIndicator);
      setNewIndDate('');
      setNewIndValue('');
      setShowAddDialog(false);
      fetchPerfInds();
    } catch (error) {
      console.error('Error posting new performance indicator:', error);
    }
  };

  const savePerformanceInd = () => {
    if (editingInd) {
      console.log('Updating indicator:', editingInd.id, newIndDate, newIndValue);
    } else {
      console.log('Adding new indicator:', newIndDate, newIndValue);
      handleSaveNewInd();
    }
  };

  const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;
    return (
      <div className="modal-overlay">
        <div className="modal">
          <button className="close-button" onClick={onClose}>X</button>
          {children}
        </div>
      </div>
    );
  };

  return (
    <div className="homepage">
     <div className="filter-container" style={{ fontSize: '18px' }}>
  <label 
    htmlFor="locationSelect" 
    style={{ marginRight: '10px', fontSize: '18px' }} // Match the font size of the label
  >
    Select location:
  </label>
  <select
    id="locationSelect"
    value={selectedLocation ? selectedLocation : ''}
    onChange={handleLocationChange}
    style={{
      fontSize: '18px',   // Match font size with label
      height: '28px',     // Set the height to match the label size
      padding: '4px',     // Add padding for consistency with the previous block
    }}
  >
    <option value="">Select Location</option>
    {locations.map((location) => (
      <option key={location._id} value={location._id}>
        {location.name}
      </option>
    ))}
  </select>
</div>

      <div className="main-grid">


        {/* Staff Sickness Records and Log Events */}
        <div className="staff-sickness-records">
        <h2>Staff on holiday</h2>
          <ul className="holiday-list">
            {staffCurrentHolidays.length === 0 ? (
              <li>No staff on holiday</li>
            ) : (
              staffCurrentHolidays.map((record) => (
                <li key={record._id}>
                  {`${record.staff_name} (${record.role} )- ${formatDate(record.start)} to ${formatDate(record.end)}`}
                </li>
              ))
            )}
          </ul>



          <h2>Staff on sick leave</h2>
          <ul className="sickness-list">
            {staffSickness.length === 0 ? (
              <li>No staff off sick</li>
            ) : (
              staffSickness.map((record) => (
                <li key={record._id}>
                  {`${record.staff_name} (${record.role} )-   from ${formatDate(record.created)}`}
                </li>
              ))
            )}
          </ul>
  
{/* Log Events Section */}
<h2>Today's staffing</h2>
<ul className="log-events-list">
 {logArraySet.length === 0 ? (

    <li>No staff due in today</li>
  ) : (
    logArraySet.map((event, index) => {



      // Get the current date and time
      const currentTime = new Date();
      // Split planned_login into hours and minutes
      const [loginHours, loginMinutes] = event.planned_login.split(':');
      // Create a new date object for the planned login time
      const plannedLoginTime = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate(), loginHours, loginMinutes);
      
        let backgroundColor='';
      // Check if planned login time is before the current time
      if (event.planned_login){
        backgroundColor = (plannedLoginTime < currentTime && event.event_type && event.event_type.includes('Due in')) ? 'lightcoral' : 'transparent';
      }
      else {
          backgroundColor='lightgreen';
      }
      return (
        <li key={index} style={{ backgroundColor }}>
          {event.staff_name} - {event.event_type}
        </li>
      );
    })
  )}
</ul>

 
         </div>
  
        <div className="column performance-indicators">
  <div className="performance-container">
    <h3>Performance Indicators</h3>
    <button onClick={() => openAddDialog()}>Add item count</button>
  </div>
   
  <div style={{ marginTop: '20px' }}>
  <ResponsiveContainer width="100%" height={275}>
    <LineChart data={perfIndsGraph}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="created"
        tickFormatter={(tick) => formatDate(tick)}
        angle={-35}
        textAnchor="end"
      />
      <YAxis />
      <Tooltip 
        content={({ payload }) => {
          if (payload && payload.length) {
            return (
              <div className="custom-tooltip">
                <p>{`Item count: ${payload[0].value}`}</p>
              </div>
            );
          }
          return null;
        }}
      />
      <Legend />
      <Line
        type="monotone"
        dataKey="value"
        stroke="#82ca9d"
        activeDot={{
          onClick: (e, index) => {
            const clickedPoint = perfIndsGraph[index];
            // Open the edit dialog with the clicked performance indicator
            openAddDialog(clickedPoint);
          }
        }}
      />
    </LineChart>
  </ResponsiveContainer>
</div>


  </div>
  
        <div className="column staff-holidays">
  <h3>Holidays awaiting approval</h3>
  <div className="grid-container bordered">
    {staffHolidays.length === 0 ? (
      <div>There are no holidays to review.</div>
    ) : (
      staffHolidays.map((holiday) => (
        <div key={holiday.id} className="grid-item">
          <span>{holiday.staff_name} - {holiday.type}</span>
          <span>{formatDate(holiday.start)} - {formatDate(holiday.end)}</span> {/* Format start date */}
          
          <button onClick={() => openHolidayDialog(holiday._id)}>Review</button>
        </div>
      ))
    )}
  </div>

{/* Log events to review section inside the same column */}
<h3>Log events to review</h3>
<div className="log-events-grid bordered">
  {logAnomalies.length === 0 ? (
    <div>No log events to review.</div>
  ) : (
    logAnomalies.map((anomaly, index) => (
      <div key={index} className="log-event-item" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <div style={{ marginRight: '10px' }}>{formatDate(anomaly.created)}</div>
          <div style={{ marginRight: '10px' }}>{anomaly.staff_name}</div>
          <div>
            {anomaly.login_variance < 15 && ' Late login '}
            {anomaly.logout_variance < 0 && ' Early logout '}
            {anomaly.overtime_requested && ' Overtime requested '}
          </div>
        </div>
        <button 
          onClick={(e) => {
            e.stopPropagation(); // Prevent the parent div's onClick from firing
            openLogEventReview(anomaly);
          }}
          style={{ marginLeft: '10px' }} 
        >
          REVIEW
        </button>
      </div>
    ))
  )}
</div>



</div>

</div>

{/* Add/Edit Performance Indicator Modal */}
{showAddDialog && (
  <div className="popup-overlay" onClick={() => setShowAddDialog(false)}>
    <div className="popup" onClick={(e) => e.stopPropagation()}> {/* Prevents click from closing the modal */}
      <h4>{editingInd ? 'Edit Performance Indicator' : 'Add Performance Indicator'}</h4>
      <div>
        <label>Date:</label>
        <input
          type="date"
          value={newIndDate}
          onChange={(e) => setNewIndDate(e.target.value)}
        />
      </div>
      <div>
        <label>Item count:</label>
        <input
          type="number"
          value={newIndValue}
          onChange={(e) => setNewIndValue(e.target.value)}
        />
      </div>
      <button onClick={savePerformanceInd}>
        {editingInd ? 'Save Changes' : 'Add'}
      </button>
      <button onClick={() => setShowAddDialog(false)} style={{ marginLeft: '10px' }}>
        Close
      </button>
    </div>
  </div>
)}
  

   {/* Conditionally render the ReviewHoliday component when a holiday is selected */}
   {selectedHolidayId && (
                <ReviewHoliday 
                    staffHolidayId={selectedHolidayId} 
                    onClose={closeReviewHolidayPopup} 
                />
            )}

      {/* Review Log Event Dialog */}
      {showReviewDialog && (
        <ReviewLogEvent
          isOpen={showReviewDialog}
          onClose={handleDialogClose}
          logEvent={selectedLogAnomaly}
        />
      )}
  
    </div>
  );
  
};

export default Homepage;
