 // src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import axios from 'axios';
import logo from './assets/logo.png';
import Calendar from './Calendar';
import Timesheets from './Timesheets';
import Staff from './Staff';
import HolidayRota from './holidayrota';
import GroupView from './GroupView';
import Homepage from './Homepage';
import Settings from './Settings';
import Planning from './planning'; // Import Planning component

const App = () => {
  // ...your existing code

  return (
    <Router>
      <div className="app-container">
        <header className="app-header">
          <img src={logo} alt="Company Logo" className="logo" />
          <h1>Pharmace Staff Management</h1>
          <div className="header-buttons">
            <Link to="/" className="header-button">Home</Link>
            <Link to="/staff" className="header-button">Staff</Link>
            <Link to="/timesheets" className="header-button">Timesheets</Link>
            <Link to="/calendar" className="header-button">Calendar</Link>
            <Link to="/holidayrota" className="header-button">Holiday Rota</Link>
            <Link to="/groupview" className="header-button">Group View</Link>
            <Link to="/settings" className="header-button">Configuration</Link>
            <Link to="/planning" className="header-button">Planning</Link> {/* New button */}
          </div>
        </header>
        <main className="app-main">
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/staff" element={<Staff />} />
            <Route path="/timesheets" element={<Timesheets />} />
            <Route path="/calendar" element={<Calendar />} />
            <Route path="/holidayrota" element={<HolidayRota />} />
            <Route path="/groupview" element={<GroupView />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/planning" element={<Planning />} /> {/* New route */}
          </Routes>
        </main>
      </div>
    </Router>
  );
};

export default App;
